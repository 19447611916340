import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

// Styles
import styles from './HuntsSelector.module.css'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'

const HuntsSelector = ({ handleHuntsToDisplayChange }) => {
  const resetedHuntToDisplayState = {
    myHunts: false,
    upcomingHunts: false,
    pastHunts: false
  }
  const [huntsToDisplay, setHuntsToDisplay] = useState({
    myHunts: true,
    upcomingHunts: false,
    pastHunts: false
  })
  const location = useLocation()

  const handleSelectorClick = (event) => {
    const { id } = event.target
    setHuntsToDisplay({ ...resetedHuntToDisplayState, [id]: true })
  }

  useEffect(() => {
    handleHuntsToDisplayChange(huntsToDisplay)
  }, [huntsToDisplay])

  useEffect(() => {
    if (location?.state?.pastHunts) {
      setHuntsToDisplay({ ...resetedHuntToDisplayState, pastHunts: true })
      window.history.replaceState({}, document.title)
    }
  }, [])

  return (
    <div className={styles.container}>
      <div
        id='myHunts'
        className={`${styles['my-hunts']} ${
          huntsToDisplay.myHunts ? styles.active : ''
        }`}
        onClick={handleSelectorClick}
      >
        {huntsTexts.huntsPage.selector.myHunts}
      </div>
      <div
        id='upcomingHunts'
        className={`${styles['upcoming-hunts']} ${
          huntsToDisplay.upcomingHunts ? styles.active : ''
        }`}
        onClick={handleSelectorClick}
      >
        {huntsTexts.huntsPage.selector.upcomingHunts}
      </div>
      <div
        id='pastHunts'
        className={`${styles['past-hunts']} ${
          huntsToDisplay.pastHunts ? styles.active : ''
        }`}
        onClick={handleSelectorClick}
      >
        {huntsTexts.huntsPage.selector.pastHunts}
      </div>
    </div>
  )
}

HuntsSelector.propTypes = {
  handleHuntsToDisplayChange: PropTypes.func
}

export default HuntsSelector
