import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import Input from 'components/UI/Input/Input'

// Helpers
import { translateMessage } from 'helpers/textFormat'

// Styles
import styles from './UserFromPassword.module.css'

// Texts
import userTexts from 'assets/texts/user/fr.json'

const UserFormPassword = ({
  handleChange,
  errors,
  resetInputs,
  setResetInputs
}) => {
  const [inputValue, setInputValue] = useState()

  const inputChangeHandler = ({ currentTarget: { name, value } }) => {
    setInputValue((previousState) => {
      return { ...previousState, [name]: value }
    })
  }

  useEffect(() => {
    inputValue && handleChange({ key: 'password', value: inputValue })
  }, [inputValue])

  useEffect(() => {
    if (resetInputs) {
      setInputValue(null)
      setResetInputs(false)
    }
  }, [resetInputs])

  return (
    <div className={styles.container}>
      <Input
        id='old_password'
        type='password'
        name='old_password'
        htmlFor='old_password'
        className={styles.oldPassword}
        labelText={userTexts.profile.form.inputs.oldPassword.label}
        onChange={inputChangeHandler}
        value={inputValue?.old_password}
        required={true}
      />
      {errors?.old_password && (
        <div className={styles.error}>
          {translateMessage(errors.old_password[0])}
        </div>
      )}
      <Input
        id='password'
        type='password'
        name='password'
        htmlFor='password'
        className={styles.password}
        labelText={userTexts.profile.form.inputs.password.label}
        onChange={inputChangeHandler}
        value={inputValue?.password}
        required={true}
      />
      {errors?.password && (
        <div className={styles.error}>
          {translateMessage(errors.password[0])}
        </div>
      )}
      <Input
        id='password_confirmation'
        type='password'
        name='password_confirmation'
        htmlFor='password_confirmation'
        className={styles['password-confirmation']}
        labelText={userTexts.profile.form.inputs.passwordConfirmation.label}
        onChange={inputChangeHandler}
        value={inputValue?.password_confirmation}
        required={true}
      />
      {errors?.password_confirmation && (
        <div className={styles.error}>
          {translateMessage(errors.password_confirmation[0])}
        </div>
      )}
    </div>
  )
}

UserFormPassword.propTypes = {
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  resetInputs: PropTypes.bool,
  setResetInputs: PropTypes.func
}

export default UserFormPassword
