import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Store
import { fetchCurrentUser } from 'store/user-actions'

// Services
import usersAPI from 'services/usersAPI'

// Custom hooks
import useNotifications from 'hooks/useNotifications'

// Components
import Button from 'components/UI/Button/Button'
import UserFormAvatar from './UserFormAvatar/UserFormAvatar'
import UserFormInfo from './UserFormInfo/UserFormInfo'
import UserFormPassword from './UserFormPassword/UserFormPassword'

// Helpers
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'

// Styles
import styles from './UserForm.module.css'

// Texts
import userTexts from 'assets/texts/user/fr.json'
import { formatPhoneInput, formatPhoneRequest } from 'helpers/userValidations'

const UserForm = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.user.currentUser)
  const [formInputs, setFormInputs] = useState()
  const [resetInputs, setResetInputs] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState()
  const { openNotification, contextHolder } = useNotifications()

  const handleFormChange = (data) => {
    setHasChanged(true)
    setFormInputs((previousState) => {
      return {
        ...previousState,
        [data.key]: { ...previousState[data.key], ...data.value }
      }
    })
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      formInputs.info.phone = formatPhoneRequest(formInputs.info.phone)
      formInputs.info && await usersAPI.updateUser(formInputs.info)
      formInputs.password && await usersAPI.changeUserPassword(formInputs.password)
      setResetInputs(true)
      setHasChanged(false)
      dispatch(fetchCurrentUser(getAuthTokenFromLocalStorage))
      openNotification({
        message: userTexts.profile.form.notifications.success,
        className: 'notification-success'
      })
    } catch (error) {
      console.log(error)
      setErrors(error.response.data)
      openNotification({
        message: userTexts.profile.form.notifications.error,
        className: 'notification-error'
      })
    }
    setIsLoading(false)
  }

  const handleClick = (event) => {
    if (event.target.type === 'password' || event.target.type === 'email') {
      setErrors()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    dispatch(fetchCurrentUser(getAuthTokenFromLocalStorage))
  }, [])

  useEffect(() => {
    if (currentUser) {
      setFormInputs({
        info: {
          first_name: currentUser?.first_name,
          last_name: currentUser?.last_name,
          email: currentUser?.email,
          phone: formatPhoneInput(currentUser?.phone)
        }
      })
    }
  }, [currentUser])

  const submitButton = (
      <Button
          type='button'
          content={userTexts.profile.form.actions.update}
          className='call-to-action'
          onClick={handleSubmit}
          loading={isLoading}
      />
  )

  return (
    <div className={styles.container}>
      <>{contextHolder}</>
      <UserFormAvatar
        currentUser={currentUser}
        handleChange={handleFormChange}
      />
      <UserFormInfo
        currentUser={currentUser}
        handleChange={handleFormChange}
        errors={errors}
      />
      <UserFormPassword
        handleChange={handleFormChange}
        errors={errors}
        resetInputs={resetInputs}
        setResetInputs={setResetInputs}
      />
      <div className={styles['actions-container']}>
        {hasChanged && submitButton}
      </div>
    </div>
  )
}

export default UserForm
