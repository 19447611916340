import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Custom hooks
import useNotifications from 'hooks/useNotifications'

// Components
import Initials from 'components/User/Initials/Initials'
import Modal from 'components/UI/Modal/Modal'

// Helpers
import { capitalizeUserName } from 'helpers/nameFormat'

// Styles
import styles from './HuntsDetailsParticipants.module.css'

// Icons
import verifiedTick from 'assets/icons/common/verifiedTick.svg'
import eyeIcon from 'assets/icons/common/eye.svg'
import closeIcon from 'assets/icons/common/close.svg'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'
import userTexts from 'assets/texts/user/fr.json'

const HuntsDetailsParticipants = ({
  hunt,
  deleteInvitation,
  setModalIsOpen,
  setModal
}) => {
  const currentUser = useSelector((state) => state.user.currentUser)
  const navigate = useNavigate()
  const { openNotification, contextHolder } = useNotifications()
  const invitedUsers = hunt?.invited_users?.filter(
    (invitedUser) => invitedUser.answer === 'YES'
  )
  const owner = hunt?.invited_users?.find(
    (invitedUser) => invitedUser.role === 'OWN'
  )?.user
  const currentUserIsHuntOwner = currentUser?.email === owner?.email

  const navigateToDocumentsGallery = (invitedUserEmail) =>
    navigate(
      `/app/gallery/domain/${hunt.domain}/hunt/${hunt.id}/user/${invitedUserEmail}`
    )

  const openDeleteInvitationModal = (invitationId) => {
    setModalIsOpen(true)
    setModal(
      <Modal
        title={huntsTexts.actions.deleteInvite.modal.title}
        message={huntsTexts.actions.deleteInvite.modal.message}
        confirmText={huntsTexts.actions.deleteInvite.modal.confirmText}
        cancelText={huntsTexts.actions.deleteInvite.modal.cancelText}
        onConfirm={() => deleteInvitation(invitationId)}
        onClose={() => setModalIsOpen(false)}
      />
    )
  }

  return (
    <div className={styles.container}>
      {invitedUsers.map((invitedUser) => {
        const numberOfUploadedDocuments = invitedUser.user?.documents?.length
        let userDocumentsMessage
        switch (numberOfUploadedDocuments) {
          case 0:
            userDocumentsMessage = userTexts.documents.noUploadedDocument
            break
          case 1:
            userDocumentsMessage = `${numberOfUploadedDocuments} ${userTexts.documents.uploadedDocument}`
            break
          case undefined:
            userDocumentsMessage = ''
            break
          default:
            userDocumentsMessage = `${numberOfUploadedDocuments} ${userTexts.documents.uploadedDocuments}`
            break
        }
        return (
          <div
            key={invitedUser.user.email}
            className={styles['participant-info-container']}
          >
            <div className={styles['initials-name-documents-container']}>
              <Initials user={invitedUser.user} />
              <div className={styles['name-documents-container']}>
                <div className={styles['name-certification-container']}>
                  <div className={styles['user-name']}>
                    {capitalizeUserName(invitedUser.user, currentUser)}
                  </div>
                  {numberOfUploadedDocuments === 3 && (
                    <img src={verifiedTick} />
                  )}
                </div>
                <div className={styles['user-documents']}>
                  {userDocumentsMessage}
                </div>
              </div>
            </div>
            {currentUserIsHuntOwner && (
              <div className={styles['owner-actions']}>
                {numberOfUploadedDocuments > 0 && (
                  <div
                    className={styles['see-invited-user-documents']}
                    onClick={() =>
                      navigateToDocumentsGallery(invitedUser.user.email)
                    }
                  >
                    <img src={eyeIcon} />
                  </div>
                )}
                {invitedUser.user.email !== currentUser.email && (
                  <div
                    className={styles['remove-invited-user']}
                    onClick={() => openDeleteInvitationModal(invitedUser.id)}
                  >
                    <img src={closeIcon} />
                  </div>
                )}
              </div>
            )}
          </div>
        )
      })}
      <div
        className={styles['invite-container']}
        onClick={() => {
          navigator.clipboard.writeText(
            `${window.location.origin}${hunt.invitation_link}`
          )
          openNotification({
            message: huntsTexts.actions.notification.message,
            description: huntsTexts.actions.notification.description,
            className: 'notification-success'
          })
        }}
      >
        <>{contextHolder}</>
        <p>{huntsTexts.actions.invite.link}</p>
      </div>
    </div>
  )
}

HuntsDetailsParticipants.propTypes = {
  hunt: PropTypes.object,
  deleteInvitation: PropTypes.func,
  setModalIsOpen: PropTypes.func,
  setModal: PropTypes.func
}

export default HuntsDetailsParticipants
