import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

// Components
import Button from 'components/UI/Button/Button'

// Styles
import styles from './Modal.module.css'

// Icons
import closeIcon from 'assets/icons/common/close.svg'

const Backdrop = ({ onClose }) => {
  return <div className={styles['backdrop']} onClick={onClose}></div>
}

const ModalOverlay = ({
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onClose,
  isLoading
}) => {
  return (
    <div className={styles['modal-container']} >
      <div className={styles['modal']}>
        <div className={styles['close-container']} onClick={onClose}>
          <img src={closeIcon} />
        </div>
        <div className={styles['title-container']}>{title}</div>
        <div className={styles['message-container']}>{message}</div>
        <div className={styles['confirm-button-container']}>
          <Button
            type='button'
            content={confirmText}
            className='call-to-action'
            onClick={onConfirm}
            loading={isLoading}
          />
        </div>
        <div className={styles['cancel-button-container']}>
          <Button
            type='button'
            content={cancelText}
            className='inversed-call-to-action'
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  )
}

const portalElement = document.getElementById('overlays')

const Modal = ({
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onClose,
  isLoading
}) => {
  return (
    <>
      {ReactDOM.createPortal(<Backdrop onClose={onClose} />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay
          title={title}
          message={message}
          confirmText={confirmText}
          cancelText={cancelText}
          onConfirm={onConfirm}
          onClose={onClose}
          isLoading={isLoading}
        />,
        portalElement
      )}
    </>
  )
}

Backdrop.propTypes = {
  onClose: PropTypes.func
}

ModalOverlay.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool
}

Modal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool
}

export default Modal
