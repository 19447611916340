import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'

// Custom hooks
import useNotifications from 'hooks/useNotifications'

// Components
import Button from 'components/UI/Button/Button'
import CardsContainer from 'components/UI/CardsContainer/CardsContainer'
import DomainsList from '../DomainsList/DomainsList'
import DomainsNotFound from '../DomainsNotFound/DomainsNotFound'

// Styles
import styles from './DomainsOverlay.module.css'

// Icons
import domainsTitleIcon from 'assets/icons/domains/domainsTitle.svg'

// Texts
import domainsTexts from 'assets/texts/domains/fr.json'

const DomainsOverlay = ({ domains, actions, withTitle = true }) => {
  const navigate = useNavigate()
  const handleDomainFormRedirection = () => navigate('/app/domain-form/create')
  const location = useLocation()
  const { openNotification, contextHolder } = useNotifications()
  const domainIsUpdated = location.state && location.state.type === 'domain'

  useEffect(() => {
    if (domainIsUpdated) {
      location.state.create === 'success' && openNotification({
        message: domainsTexts.form.create.notifications.success.title,
        description: domainsTexts.form.create.notifications.success.message,
        className: 'notification-success'
      })
      location.state.delete === 'success' && openNotification({
        message: domainsTexts.form.delete.notifications.success.title,
        className: 'notification-success'
      })
      window.history.replaceState({}, document.title)
    }
  }, [location])

  if (domains.length === 0) {
    return <DomainsNotFound handleOnClick={handleDomainFormRedirection} />
  } else {
    return (
      <div className={styles.container}>
        <>{contextHolder}</>
        <div className={styles.content}>
          {withTitle && (
            <div className={styles.title}>
              <img src={domainsTitleIcon} />
              <h3>
                {domains.length === 1
                  ? domainsTexts.home.withDomain.myDomain
                  : domainsTexts.home.withDomain.myDomains}
              </h3>
            </div>
          )}
          <CardsContainer>
            <DomainsList domains={domains} actions={actions} />
          </CardsContainer>
          <div className={styles.button}>
            <Button
              type='button'
              content={domainsTexts.home.withDomain.create}
              className='call-to-action'
              onClick={handleDomainFormRedirection}
            />
          </div>
        </div>
      </div>
    )
  }
}

DomainsOverlay.propTypes = {
  domains: PropTypes.array,
  actions: PropTypes.array,
  withTitle: PropTypes.bool
}

export default DomainsOverlay
