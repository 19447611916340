import React from 'react'
import styles from './Species.module.css'
import capitalize from 'antd/es/_util/capitalize'
import PropTypes from 'prop-types'

const Species = ({ species, color, withInfo }) => {
  return (
    <div
        style={{ borderColor: color, color }}
        className={withInfo ? styles.species : styles['species-without-info']}
    >
        {capitalize(species)}
    </div>
  )
}
Species.propTypes = {
  species: PropTypes.string,
  color: PropTypes.string,
  withInfo: PropTypes.bool
}
export default Species
