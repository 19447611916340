import * as jose from 'jose'

export const setAuthTokenToLocalStorage = (tokenData) => {
  localStorage.setItem('token', tokenData.access)
  localStorage.setItem('refreshToken', tokenData.refresh)
  const decodedToken = jose.decodeJwt(tokenData.access)
  localStorage.setItem('userId', decodedToken.user_id)
}

export const getAuthTokenFromLocalStorage = () => {
  const token = localStorage.getItem('token')
  return token
}

export const logout = () => {
  localStorage.removeItem('userId')
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  return null
}

export const isJWT = (token) => {
  try {
    const decodedToken = jose.decodeJwt(token)
    return decodedToken && decodedToken.token_type === 'access'
  } catch (error) {
    console.log(error)
    return false
  }
}
