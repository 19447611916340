import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Components
import SectionTitle from 'components/UI/SectionTitle/SectionTitle'

// Helpers
import { resizeImage } from 'helpers/imagesHelper'

// styles
import styles from './DomainFormPlan.module.css'

// Icons
import addPlan from 'assets/icons/common/addPlan.svg'
import closeIcon from 'assets/icons/common/close.svg'
import infoIcon from 'assets/icons/common/info.svg'
import pictureIcon from 'assets/icons/common/picture.svg'
import planIcon from 'assets/icons/domains/domainsPlan.svg'

// Texts
import domainsTexts from 'assets/texts/domains/fr.json'
import Input from 'components/UI/Input/Input'

const DomainFormPlan = ({ formChangeHandler, domain }) => {
  const imageReference = useRef()
  const [planFiles, setPlanFiles] = useState([])
  const [isInitial, setIsInital] = useState(true)

  const handlePlanDelete = (index) => {
    const newPlanFiles = [...planFiles]
    newPlanFiles.splice(index, 1)
    setPlanFiles(newPlanFiles)
  }

  const handlePlanNameUpdate = (newValue, index) => {
    const newPlanFiles = [...planFiles]
    newPlanFiles[index].name = newValue
    setPlanFiles(newPlanFiles)
  }

  useEffect(() => {
    formChangeHandler({ plans: planFiles })
  }, [planFiles])

  const convertPlanToFile = async (plan) => {
    const response = await fetch(plan.image)
    const blob = await response.blob()
    const file = new File(
      [blob],
      plan.image.substring(plan.image.lastIndexOf('/') + 1).split('?')[0],
      { type: blob.type }
    )
    setPlanFiles(previousState => [...previousState, { image: file, name: plan.name }])
  }

  useEffect(() => {
    if (isInitial && domain?.plans && domain.plans.length > 0) {
      domain.plans.forEach(plan => {
        convertPlanToFile(plan)
      })
      setIsInital(false)
    }
  }, [domain])

  return (
    <div className={styles.container}>
      <SectionTitle
        icon={pictureIcon}
        textContent={domainsTexts.form.addPlan.title}
      />
      <div className={styles['plan-container']}>
        <div className={styles.infos}>
          <img className={styles['info-icon']} src={infoIcon} />
          <div className={styles.info}>
            <p>{domainsTexts.form.addPlan.info}</p>
            <Link to='/plan-tutorial' target="_blank">{domainsTexts.form.addPlan.link}</Link>
          </div>
          <img src={planIcon} />
        </div>
        <div className={styles['plans-container']}>
        {
          planFiles.map((plan, index) => {
            return (
                <div key={index} className={styles['plan-image']}>
                  <Input
                      id={`planName_${index}`}
                      htmlFor={`planName_${index}`}
                      type='text'
                      containerClassname={styles['input-container']}
                      inputClassname={styles['input-plan']}
                      labelClassname={styles['label-plan']}
                      placeholder={domainsTexts.form.addPlan.inputName.placeholder}
                      labelText={domainsTexts.form.addPlan.inputName.label}
                      value={plan.name}
                      onChange={e => handlePlanNameUpdate(e.target.value, index)}
                  />
                  <div className={styles['image-and-close-button-container']} >
                    <div
                        className={styles.close}
                        onClick={() => handlePlanDelete(index)}
                    >
                      <img src={closeIcon} />
                    </div>
                    <img src={URL.createObjectURL(plan.image)} />
                  </div>
                </div>
            )
          })
        }
        <label htmlFor='imageInputPlan' className={styles.label}>
          <div className={styles.default} ><img id='imageTagPlan' src={addPlan}></img></div>
        </label>
        <input
          id='imageInputPlan'
          className={styles.input}
          type='file'
          accept='.jpg,.jpeg,.png'
          multiple
          ref={imageReference}
          onChange={async () => {
            // eslint-disable-next-line no-undef
            const files = imageInputPlan.files
            if (files) {
              const resizedFiles = []
              for (const file of files) {
                const image = await resizeImage(file)
                resizedFiles.push({ name: '', image })
              }
              setPlanFiles((previousState) => [
                ...previousState,
                ...resizedFiles
              ])
              // eslint-disable-next-line no-undef
              imageInputPlan.value = ''
            }
          }}
        />
        </div>
      </div>
    </div>
  )
}

DomainFormPlan.propTypes = {
  formChangeHandler: PropTypes.func,
  domain: PropTypes.object
}

export default DomainFormPlan
