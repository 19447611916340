import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Store
import { fetchCurrentUser } from 'store/user-actions'

// Components
import DomainsOverlay from 'components/Domains/DomainsOverlay/DomainsOverlay'
import Footer from 'components/UI/Footer/Footer'
import Header from 'components/UI/Header/Header'

// Helpers
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'

// Styles
import styles from './DomainsPage.module.css'

// Texts
import domainsTexts from 'assets/texts/domains/fr.json'

const DomainsPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = getAuthTokenFromLocalStorage()
  const currentUser = useSelector((state) => state.user.currentUser)
  const navigateToDomainForm = (id) => navigate(`/app/domain-form/${id}`)
  const navigateToHuntForm = (id) =>
    navigate('/app/hunt-form/create', { state: { domainId: id } })
  const domainActions = [
    {
      text: domainsTexts.domainsPage.actions.update,
      action: navigateToDomainForm
    },
    {
      text: domainsTexts.domainsPage.actions.create,
      action: navigateToHuntForm
    }
  ]

  useEffect(() => {
    dispatch(fetchCurrentUser(token))
  }, [])

  if (currentUser) {
    return (
      <>
        <Header titleText={domainsTexts.domainsPage.title}>
          <>
            <div className={styles.content}>
              <DomainsOverlay
                domains={currentUser.domains}
                actions={domainActions}
                withTitle={false}
              />
            </div>
            <div className={styles.footer}>
              <Footer />
            </div>
          </>
        </Header>
      </>
    )
  }
}

export default DomainsPage
