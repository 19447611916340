import usersAPI from 'services/usersAPI'
import * as jose from 'jose'

import { userActions } from './user-slice'
import { uiActions } from './ui-slice'

import authenticationAPI from 'services/authenticationAPI'
import { setAuthTokenToLocalStorage } from 'helpers/authentication'

export const signupUser = (credentials) => {
  return async (dispatch) => {
    const signup = async () => {
      await usersAPI.createNewUser(credentials)
      const tokenData = await authenticationAPI
        .getToken(credentials)
        .then((response) => response.data)
      setAuthTokenToLocalStorage(tokenData)
      const decodedToken = jose.decodeJwt(tokenData.access)
      dispatch(fetchCurrentUser(decodedToken.user_id))
    }
    try {
      dispatch(userActions.setIsLoaded(false))
      await signup(credentials)
      dispatch(userActions.setIsAuthenticated(true))
      dispatch(uiActions.resetErrors())
    } catch (error) {
      const errors = error.response.data
      dispatch(uiActions.setErrors(errors))
    } finally {
      dispatch(userActions.setIsLoaded(true))
    }
  }
}

export const loginUser = (credentials) => {
  return async (dispatch) => {
    const login = async () => {
      const tokenData = await authenticationAPI
        .getToken(credentials)
        .then((response) => response.data)
      setAuthTokenToLocalStorage(tokenData)
      const decodedToken = jose.decodeJwt(tokenData.access)
      dispatch(fetchCurrentUser(decodedToken.user_id))
    }
    try {
      dispatch(userActions.setIsLoaded(false))
      await login(credentials)
      dispatch(userActions.setIsAuthenticated(true))
      dispatch(uiActions.resetErrors())
      return true
    } catch (error) {
      if (error.response.status === 401) {
        const errors = error.response.data
        dispatch(uiActions.setErrors(errors))
      }
    } finally {
      dispatch(userActions.setIsLoaded(true))
    }
  }
}

export const forgotUserPassword = ({ email }) => {
  return async (dispatch) => {
    const forgotPassword = async () => {
      await usersAPI.forgotUserPassword({ email })
    }
    try {
      await forgotPassword()
      dispatch(uiActions.resetErrors())
      return true
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data
        dispatch(uiActions.setErrors(errors))
      }
    }
  }
}

export const resetUserPassword = ({ password, token }) => {
  return async (dispatch) => {
    const resetPassword = async () => {
      await usersAPI.resetUserPassword({ password, token })
    }
    try {
      await resetPassword()
      dispatch(uiActions.resetErrors())
      return true
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data
        dispatch(uiActions.setErrors(errors))
      } else if (error.response.status === 404) {
        const errors = { confirmPassword: [error.response.data.detail] }
        dispatch(uiActions.setErrors(errors))
      }
    }
  }
}

export const fetchCurrentUser = (id) => {
  return async (dispatch) => {
    const fetchUser = async (id) => {
      const user = await usersAPI.getUser(id)
      return user
    }
    try {
      dispatch(userActions.setIsLoaded(false))
      const userData = await fetchUser(id)
      dispatch(userActions.setCurrentUser(userData))
      dispatch(uiActions.resetErrors())
    } catch (error) {
      const errors = error.response.data
      dispatch(uiActions.setErrors(errors))
    } finally {
      dispatch(userActions.setIsLoaded(true))
    }
  }
}
