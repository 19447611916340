export const formatDateToFrenchDate = (date, options) => {
  const localDate = date.toLocaleDateString('fr', options)
  return localDate
}

export const formatDay = (date) => {
  const formattedDay =
    (new Date(date).getDate() < 10 ? '0' : '') + (new Date(date).getDate())
  return formattedDay
}

export const formatMonth = (date) => {
  const formattedMonth =
    (new Date(date).getMonth() + 1 < 10 ? '0' : '') + (new Date(date).getMonth() + 1)
  return formattedMonth
}

export const formatHours = (date) => {
  const formattedHours =
    (new Date(date).getHours() < 10 ? '0' : '') + new Date(date).getHours()
  return formattedHours
}

export const formatMinutes = (date) => {
  const formattedMinutes =
    (new Date(date).getMinutes() < 10 ? '0' : '') + new Date(date).getMinutes()
  return formattedMinutes
}

export const addOneDayToDate = (date) => {
  date.setDate(date.getDate() + 1)

  return date
}

export const formatDate = ({ format, date }) => {
  const dateObject = new Date(date)
  switch (format) {
    case 'YYYY-MM-DD':
      return `${dateObject.getFullYear()}-${formatMonth(date)}-${formatDay(date)}`
    case 'HH:mm':
      return `${formatHours(date)}:${formatMinutes(date)}`
    default:
      break
  }
}
