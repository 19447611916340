export const API_URL = process.env.REACT_APP_API_URL

export const JWT_TOKEN_API = `${API_URL}/api/token/`
export const JWT_REFRESH_TOKEN_API = `${API_URL}/api/token/refresh/`

export const CHANGE_PASSWORD_API = `${API_URL}/api/change_password`
export const DELETE_HUNT_API = `${API_URL}/api/delete_hunt`
export const DOCUMENT_API = `${API_URL}/api/document`
export const DOMAIN_API = `${API_URL}/api/domain`
export const UPDATE_DOMAIN_API = `${API_URL}/api/update_domain`
export const DELETE_DOMAIN_API = `${API_URL}/api/delete_domain`
export const ADD_HUNT_PHOTOS_API = `${API_URL}/api/hunt_photos`
export const UPDATE_HUNT_API = `${API_URL}/api/update_hunt`
export const HUNT_API = `${API_URL}/api/hunt`
export const DELETE_INVITATION_API = `${API_URL}/api/delete_invitation`
export const UPDATE_INVITATION_API = `${API_URL}/api/update_invitation`
export const USER_API = `${API_URL}/api/user`
export const USER_DETAILS_API = `${API_URL}/api/user_details`
export const USER_UPDATE_API = `${API_URL}/api/update_user`
export const FORGOT_PASSWORD_API = `${API_URL}/api/password_reset/`
export const RESET_PASSWORD_API = `${API_URL}/api/password_reset/confirm/`
