import React from 'react'
import PropTypes from 'prop-types'
import styles from './InvitationLinkButton.module.css'
import invitationLogo from 'assets/icons/hunts/invitationLogo.svg'
import huntsTexts from 'assets/texts/hunts/fr.json'
import useNotifications from 'hooks/useNotifications'
const InvitationLinkButton = ({ content = '', invitationLinkUrl = '' }) => {
  const { openNotification, contextHolder } = useNotifications()
  const onClick = () => {
    navigator.clipboard.writeText(
      invitationLinkUrl
    )
    openNotification({
      message: huntsTexts.actions.notification.message,
      description: huntsTexts.actions.notification.description,
      className: 'notification-success'
    })
  }
  return (
      <>
          {contextHolder}
        <button
            className={styles['invitation-button']}
            onClick={onClick}
        >
            <img src={invitationLogo} className={styles.logo} />
            {content}
        </button>
      </>
  )
}
InvitationLinkButton.propTypes = {
  content: PropTypes.string,
  invitationLinkUrl: PropTypes.string
}
export default InvitationLinkButton
