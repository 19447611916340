import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentUser: null,
  isAuthenticated: false,
  isLoaded: false
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetInitialState (state) {
      state.currentUser = null
      state.isAuthenticated = false
      state.isLoaded = false
    },
    setCurrentUser (state, action) {
      state.currentUser = action.payload
    },
    setIsAuthenticated (state, action) {
      state.isAuthenticated = action.payload
    },
    setIsLoaded (state, action) {
      state.isLoaded = action.payload
    }
  }
})

export const userActions = userSlice.actions

export default userSlice
