import axios from 'axios'
import {
  ADD_HUNT_PHOTOS_API,
  DELETE_HUNT_API,
  DELETE_INVITATION_API,
  HUNT_API,
  UPDATE_HUNT_API,
  UPDATE_INVITATION_API
} from 'services/endpoints'
import axiosService from './axiosService'
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'

const getHuntByAccessKey = async (accessKey) => {
  const response = await axios.get(HUNT_API + '/' + accessKey)
  return response.data
}

const updateHuntInvitationAnswer = async (huntId, answer) => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  const response = await axios.post(UPDATE_INVITATION_API, {
    hunt_id: huntId,
    answer
  })
  return response.data
}

const deleteHuntInvitation = async (invitationId) => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  const response = await axios.delete(DELETE_INVITATION_API + '/' + invitationId + '/')
  return response
}

const createHunt = async (data) => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  const response = await axios.post(HUNT_API, data)
  return response
}

const updateHunt = async ({ id, data }) => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  const response = await axios.patch(UPDATE_HUNT_API + '/' + id + '/', data)
  return response
}

const deleteHunt = async (huntId) => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  const response = await axios.delete(`${DELETE_HUNT_API}/${huntId}/`)
  return response.data
}

const addHuntPhotos = async (huntId, data) => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  const formData = new FormData()
  if (data.photos.length > 0) {
    data.photos.forEach((file) => {
      formData.append('photos', file)
    })
  }
  const response = await axios.post(`${ADD_HUNT_PHOTOS_API}/${huntId}/`, formData)
  return response.data
}

export default {
  getHuntByAccessKey,
  updateHuntInvitationAnswer,
  deleteHuntInvitation,
  createHunt,
  updateHunt,
  deleteHunt,
  addHuntPhotos
}
