import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Styles
import styles from './Avatar.module.css'

// Icons
import smallAvatarIcon from 'assets/icons/user/smallAvatar.svg'

const Avatar = () => {
  const navigate = useNavigate()
  const currentUser = useSelector((state) => state.user.currentUser)

  const handleAvatarClick = () => {
    navigate('/app/user')
  }

  return (
    <div className={styles.avatar} onClick={handleAvatarClick}>
      {
        currentUser?.avatar
          ? <div className={styles['user-avatar']} ><img src={currentUser.avatar}/></div>
          : <div className={styles['default-avatar']} ><img src={smallAvatarIcon}/></div>
      }
    </div>
  )
}

export default Avatar
