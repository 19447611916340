import React from 'react'
import { createBrowserRouter, generatePath, Navigate, RouterProvider, useParams } from 'react-router-dom'

// Layouts
import RootLayout from 'layouts/RootLayout/RootLayout'
import AppLayout from 'layouts/AppLayout/AppLayout'

// Pages
import AuthenticationPage from 'pages/AuthenticationPage/AuthenticationPage'
import DomainFormPage from 'pages/DomainFormPage/DomainFormPage'
import DomainsPage from 'pages/DomainsPage/DomainsPage'
import GalleryPage from 'pages/GalleryPage/GalleryPage'
import HomePage from 'pages/HomePage/HomePage'
import HuntDetailsPage from 'pages/HuntDetailsPage/HuntDetailsPage'
import HuntFormPage from 'pages/HuntFormPage/HuntFormPage'
import HuntsPage from 'pages/HuntsPage/HuntsPage'
import InvitationPage from 'pages/InvitationPage/InvitationPage'
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage'
import UserDocumentsPage from 'pages/UserDocumentsPage/UserDocumentsPage'
import UserPage from 'pages/UserPage/UserPage'
import UserProfilePage from 'pages/UserProfilePage/UserProfilePage'
import PlanTutorialPage from 'pages/PlanTutorialPage/PlanTutorialPage'
import LandingPage from 'pages/LandingPage/LandingPage'

const FixWrongHuntLink = () => {
  const params = useParams()
  const id = parseInt(params.id)
  return (<Navigate to={generatePath(`/app/hunt/${id}`)} />)
}

const router = createBrowserRouter([
  {
    path: 'app',
    element: <RootLayout />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: '',
        element: <AppLayout />,
        children: [
          {
            path: '',
            element: <HomePage />
          },
          {
            path: 'domains',
            element: <DomainsPage />
          },
          {
            path: 'domain-form/:id',
            element: <DomainFormPage />
          },
          {
            path: 'hunts',
            element: <HuntsPage />
          },
          {
            path: 'hunt/:id',
            element: <HuntDetailsPage />
          },
          {
            path: 'hunt-form/:id',
            element: <HuntFormPage />
          },
          {
            path: 'user',
            element: <UserPage />
          },
          {
            path: 'user/documents',
            element: <UserDocumentsPage />
          },
          {
            path: 'user/profile',
            element: <UserProfilePage />
          },
          {
            path: 'gallery/:huntId',
            element: <GalleryPage />
          },
          {
            path: 'gallery/domain/:domainId/hunt/:huntId/user/:userEmail',
            element: <GalleryPage />
          }
        ]
      }
    ]
  },
  {
    path: '',
    element: <LandingPage />
  },
  {
    path: 'auth',
    element: <AuthenticationPage />
  },
  {
    path: '/invitation/:access_key',
    element: <InvitationPage />
  },
  { // To remove after max rendez-vous date of hunts created before 17/11/23
    path: 'hunt/:id',
    element: <FixWrongHuntLink />
  },
  {
    path: 'plan-tutorial',
    element: <PlanTutorialPage />
  }
])

const App = () => {
  return <RouterProvider router={router} />
}

export default App
