import React from 'react'
import PropTypes from 'prop-types'

// Components
import Button from 'components/UI/Button/Button'

// Styles
import styles from './DomainsNotFound.module.css'

// Icons
import domainsNotFoundIcon from 'assets/icons/domains/domainsNotFound.svg'

// Texts
import domainsTexts from 'assets/texts/domains/fr.json'

const DomainsNotFound = ({ handleOnClick }) => {
  return (
    <div className={styles.grid}>
      <div className={styles.container}>
        <img src={domainsNotFoundIcon} />
        <h3>{domainsTexts.home.noDomain.title}</h3>
        <p>
          {domainsTexts.home.noDomain.info}
        </p>
        <Button
          type='button'
          content={domainsTexts.home.noDomain.create}
          className='call-to-action'
          onClick={handleOnClick}
        />
      </div>
    </div>
  )
}

DomainsNotFound.propTypes = {
  handleOnClick: PropTypes.func
}

export default DomainsNotFound
