import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Store
import { fetchCurrentUser } from 'store/user-actions'

// Components
import Footer from 'components/UI/Footer/Footer'
import Header from 'components/UI/Header/Header'
import HuntsOverlay from 'components/Hunts/HuntsOverlay/HuntsOverlay'
import HuntsSelector from 'components/Hunts/HuntsSelector/HuntsSelector'

// Helpers
import { addOneDayToDate } from 'helpers/dateFormat'
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'
import { isCurrentUserHunt } from 'helpers/userValidations'

// Styles
import styles from './HuntsPage.module.css'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'
import commonTexts from 'assets/texts/common/fr.json'

const HuntsPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = getAuthTokenFromLocalStorage()
  const currentUser = useSelector((state) => state.user.currentUser)
  const [huntsToDisplay, setHuntsToDisplay] = useState()
  const myHunts = currentUser?.hunts_as_invited.filter(
    (hunt) => isCurrentUserHunt(currentUser, hunt) && addOneDayToDate(new Date(hunt.rendez_vous_datetime)) > new Date()
  )
  const upcomingHunts = currentUser?.hunts_as_invited.filter(
    (hunt) => addOneDayToDate(new Date(hunt.rendez_vous_datetime)) > new Date()
  )
  const pastHunts = currentUser?.hunts_as_invited.filter(
    (hunt) => addOneDayToDate(new Date(hunt.rendez_vous_datetime)) < new Date()
  )
  const handleDisplayDetails = (id) => navigate(`/app/hunt/${id}`)
  const huntActions = [
    { text: commonTexts.actions.findOutMore, action: handleDisplayDetails }
  ]
  const noHunt = <div className={styles['no-hunt']} >{huntsTexts.huntsPage.noHunt}</div>

  const handleHuntsToDisplayChange = (state) => {
    setHuntsToDisplay(state)
  }

  useEffect(() => {
    dispatch(fetchCurrentUser(token))
  }, [])

  if (currentUser) {
    return (
      <>
        <Header titleText={huntsTexts.huntsPage.title}>
          <>
            <div className={styles.content}>
              <HuntsSelector
                handleHuntsToDisplayChange={handleHuntsToDisplayChange}
              />
              {
                huntsToDisplay?.myHunts
                  ? <HuntsOverlay hunts={myHunts} actions={huntActions} withTitle={false} withCallToActions={false} />
                  : huntsToDisplay?.upcomingHunts
                    ? <HuntsOverlay hunts={upcomingHunts} actions={huntActions} withTitle={false} withCallToActions={false} />
                    : huntsToDisplay?.pastHunts
                      ? <HuntsOverlay hunts={pastHunts} actions={huntActions} withTitle={false} withCallToActions={false} />
                      : null
              }
              {huntsToDisplay?.myHunts && myHunts.length === 0 && noHunt}
              {huntsToDisplay?.upcomingHunts && upcomingHunts.length === 0 && noHunt}
              {huntsToDisplay?.pastHunts && pastHunts.length === 0 && noHunt}
            </div>
            <div className={styles.footer}>
              <Footer />
            </div>
          </>
        </Header>
      </>
    )
  }
}

export default HuntsPage
