import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Store
import { fetchCurrentUser } from 'store/user-actions'
import { resetStore } from 'store/store-actions'

// Helpers
import { getAuthTokenFromLocalStorage, logout } from 'helpers/authentication'
import { displayNotUpToDate } from 'helpers/userValidations'

// Components
import Header from 'components/UI/Header/Header'

// Styles
import styles from './UserPage.module.css'

// Icons
import documentsIcon from 'assets/icons/user/documents.svg'
import updateProfileIcon from 'assets/icons/user/updateProfile.svg'
import logoutIcon from 'assets/icons/user/logout.svg'

// Texts
import userTexts from 'assets/texts/user/fr.json'

const UserPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector((state) => state.user.currentUser)

  useEffect(() => {
    dispatch(fetchCurrentUser(getAuthTokenFromLocalStorage))
  }, [])

  const navigateToUserDocumentsPage = () => navigate('/app/user/documents')

  const navigateToUserProfilePage = () => navigate('/app/user/profile')

  const handleLogout = () => {
    logout()
    dispatch(resetStore())
    navigate('/auth')
  }

  if (currentUser) {
    return (
      <div>
        <Header
          titleText={userTexts.title}
          withReturn={false}
          displayNotUpToDate={displayNotUpToDate(currentUser)}
        >
          <div className={styles.container}>
            <div
              className={styles.documents}
              onClick={navigateToUserDocumentsPage}
            >
              <img src={documentsIcon} />
              <div>{userTexts.documents.myDocuments}</div>
            </div>
            <div
              className={styles['update-profile']}
              onClick={navigateToUserProfilePage}
            >
              <img src={updateProfileIcon} />
              <div>{userTexts.updateProfile}</div>
            </div>
            <div className={styles.logout} onClick={handleLogout}>
              <img src={logoutIcon} />
              <div>{userTexts.logout}</div>
            </div>
          </div>
        </Header>
      </div>
    )
  }
}

export default UserPage
