import React from 'react'
import ReactDOM from 'react-dom/client'
import App from 'App'
import { Provider } from 'react-redux'
import store from 'store/store'
import 'index.css'
import { ConfigProvider } from 'antd'
import frFR from 'antd/locale/fr_FR'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <ConfigProvider locale={frFR}>
      <App />
    </ConfigProvider>
  </Provider>
)
