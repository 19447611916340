import React from 'react'
import { Link } from 'react-router-dom'

// Styles
import styles from './Footer.module.css'

// Icons
import appIcon from 'assets/icons/common/appIcon.svg'

// Texts
import commonTexts from 'assets/texts/common/fr.json'

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles['footer-icon']}>
        <img src={appIcon} className={styles['app-icon']} />
      </div>
      <div className={styles['footer-links-container']}>
        <div className={styles['footer-links']}>
          <Link to='mailto:paulcatroux@astriddesologne.com'>{commonTexts.app.contact}</Link>
          <p>{commonTexts.app.copyright}</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
