import React from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './DateCard.module.css'

const DateCard = ({ date }) => {
  const day = date.split(' ')[0]
  const month = date.split(' ')[1].substring(0, date.split(' ')[1].length - 1)
  return (
    <div className={styles.container}>
      <div className={styles.day}>{day}</div>
      <div className={styles.month}>{month}</div>
    </div>
  )
}

DateCard.propTypes = {
  date: PropTypes.string
}

export default DateCard
