import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetStore } from 'store/store-actions'
import {
  getAuthTokenFromLocalStorage,
  isJWT,
  logout
} from 'helpers/authentication'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const RootLayout = () => {
  const dispatch = useDispatch()
  const token = getAuthTokenFromLocalStorage()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (!(token && isJWT(token))) {
      logout()
      dispatch(resetStore())
      navigate('/auth')
    }
  }, [])

  return (
    <>
      <main>
        <Outlet />
      </main>
    </>
  )
}

export default RootLayout
