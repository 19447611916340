import React, { useEffect } from 'react'

import AuthenticationForm from 'components/Authentication/AuthenticationForm/AuthenticationForm'
import { getAuthTokenFromLocalStorage, isJWT } from 'helpers/authentication'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import huntsAPI from 'services/huntsAPI'

const AuthenticationPage = () => {
  const token = getAuthTokenFromLocalStorage()
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (token && isJWT(token)) {
      if (location.state) {
        if (location.state.goTo === 'app/hunt') {
          huntsAPI.updateHuntInvitationAnswer(location.state.id, 'YES')
        }
        navigate(`/${location.state.goTo}/${location.state.id}`, { state: { huntJoined: 'success' } })
      } else {
        navigate('/app')
      }
    } else {
      navigate({ pathname: '/auth', search: location.search }, { state: location.state })
    }
  }, [isAuthenticated])
  return <AuthenticationForm />
}

export default AuthenticationPage
