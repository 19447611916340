import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

// Components
import DomainForm from 'components/Domains/DomainForm/DomainForm'
import Header from 'components/UI/Header/Header'

// Texts
import domainsTexts from 'assets/texts/domains/fr.json'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'
import { fetchCurrentUser } from 'store/user-actions'

const DomainFormPage = () => {
  const dispatch = useDispatch()
  const token = getAuthTokenFromLocalStorage()
  const currentUser = useSelector((state) => state.user.currentUser)
  const params = useParams()
  const { id } = params
  const domain = currentUser?.domains?.find(
    (domain) => domain.id === parseInt(id)
  )

  useEffect(() => {
    dispatch(fetchCurrentUser(token))
  }, [])

  return (
    <div>
      <Header
        titleText={
          domain
            ? domainsTexts.form.update.title
            : domainsTexts.form.create.title
        }
        withReturn={true}
      >
        <DomainForm domain={domain} />
      </Header>
    </div>
  )
}

export default DomainFormPage
