import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
// Store
import { fetchCurrentUser } from 'store/user-actions'

// Services
import usersAPI from 'services/usersAPI'

// Components
import Button from 'components/UI/Button/Button'
import UploadInput from 'components/UI/UploadInput/UploadInput'

// Helpers
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'
import { capitalizeUserName } from 'helpers/nameFormat'
import { getFileExtension } from 'helpers/textFormat'

// Styles
import styles from './UserDocumentsPage.module.css'

// Icons
import returnIcon from 'assets/icons/common/return.svg'
import organizerIcon from 'assets/icons/hunts/huntsOrganizer.svg'

// Texts
import userTexts from 'assets/texts/user/fr.json'
import useNotifications from 'hooks/useNotifications'
import 'pdfjs-dist/build/pdf.worker.entry'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString()

const UserDocumentsPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector((state) => state.user.currentUser)
  const [formInputs, setFormInputs] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const licenceFile = currentUser?.documents.find(
    (document) => document.type === 'LIC'
  )
  const validationFile = currentUser?.documents.find(
    (document) => document.type === 'VAL'
  )
  const insuranceFile = currentUser?.documents.find(
    (document) => document.type === 'INS'
  )
  const { openNotification, contextHolder } = useNotifications()

  const onClickReturn = () => navigate('/app/user')

  const handleFormChange = (data) => {
    setFormInputs((previousState) => {
      return { ...previousState, [data.type]: data.file }
    })
  }

  const handleUploadClick = async () => {
    setIsLoading(true)
    try {
      for (const [type, file] of Object.entries(formInputs)) {
        await usersAPI.uploadUserDocument(file, type)
      }
      navigate(0, { state: { upload: 'success' } })
    } catch (error) {
      console.log(error)
      openNotification({
        message: userTexts.documents.notifications.error,
        className: 'notification-error'
      })
    }
    setIsLoading(false)
  }
  const displayUploadButton = () => Object.keys(formInputs).length > 0

  const submitButton = (
      <Button
          type='button'
          content={userTexts.documents.upload}
          className='call-to-action'
          onClick={handleUploadClick}
          loading={isLoading}
      />
  )

  useEffect(() => {
    dispatch(fetchCurrentUser(getAuthTokenFromLocalStorage()))
  }, [])

  if (currentUser) {
    return (
      <div className={styles.container}>
        {<>{contextHolder}</>}
        <div className={styles.header}>
          <div className={styles.return} onClick={onClickReturn}>
            <img src={returnIcon} />
          </div>
          <div className={styles['title-and-user-container']}>
            <div className={styles.title}>{userTexts.documents.myDocuments}</div>
            <div className={styles['user-container']}>
              <img src={organizerIcon} />
              <div>{capitalizeUserName(currentUser)}</div>
            </div>
          </div>
        </div>
        <div className={styles['documents-container']}>
          <div className={styles.licence}>
            <p>{userTexts.documents.licence}</p>
            {
              formInputs?.LIC
                ? null
                : licenceFile
                  ? getFileExtension(licenceFile.file) === 'pdf'
                    ? <Document file={licenceFile.file} ><Page size="A4" pageNumber={1} renderTextLayer={false} /> </Document>
                    : <img src={licenceFile.file} />
                  : null
            }
            <UploadInput type='LIC' handleChange={handleFormChange} fileAlreadyExists={typeof licenceFile === 'object'} />
          </div>
          <div className={styles.validation}>
            <p>{userTexts.documents.validation}</p>
            {
              formInputs?.VAL
                ? null
                : validationFile
                  ? getFileExtension(validationFile.file) === 'pdf'
                    ? <Document file={validationFile.file} ><Page size="A4" pageNumber={1} renderTextLayer={false} /> </Document>
                    : <img src={validationFile.file} />
                  : null
            }
            <UploadInput type='VAL' handleChange={handleFormChange} fileAlreadyExists={typeof validationFile === 'object'} />
          </div>
          <div className={styles.insurance}>
            <p>{userTexts.documents.insurance}</p>
            {
              formInputs?.INS
                ? null
                : insuranceFile
                  ? getFileExtension(insuranceFile.file) === 'pdf'
                    ? <Document file={insuranceFile.file} ><Page size="A4" pageNumber={1} renderTextLayer={false} /> </Document>
                    : <img src={insuranceFile.file} />
                  : null
            }
            <UploadInput type='INS' handleChange={handleFormChange} fileAlreadyExists={typeof insuranceFile === 'object'} />
          </div>
          {displayUploadButton() && submitButton}
        </div>
      </div>
    )
  }
}

export default UserDocumentsPage
