import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

// Store
import { fetchCurrentUser } from 'store/user-actions'

// Helpers
import { capitalizeUserName, formatUserName } from 'helpers/nameFormat'
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'

// Styles
import styles from './GalleryPage.module.css'

// Icons
import returnIcon from 'assets/icons/common/return.svg'
import organizerIcon from 'assets/icons/hunts/huntsOrganizer.svg'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'
import usersTexts from 'assets/texts/user/fr.json'

const GalleryPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector((state) => state.user.currentUser)
  const [photos, setPhotos] = useState()
  const [hunt, setHunt] = useState()
  const [user, setUser] = useState()
  const [huntOwner, setHuntOwner] = useState()
  const params = useParams()
  const { domainId, huntId, userEmail } = params
  const galleryMode = userEmail ? 'documents' : 'photos'
  const currentUserIsHuntOwner = currentUser?.email === user?.email

  const onClickReturn = () => navigate(-1)

  useEffect(() => {
    dispatch(fetchCurrentUser(getAuthTokenFromLocalStorage()))
  }, [])

  useEffect(() => {
    if (currentUser && domainId && huntId && userEmail) {
      const domain = currentUser?.domains?.find(domain => domain.id === parseInt(domainId))
      const hunt = domain?.hunts?.find(hunt => hunt.id === parseInt(huntId))
      const user = hunt?.invited_users?.find(user => user.user.email === userEmail)?.user
      const huntOwner = hunt?.invited_users?.find(invitedUser => invitedUser.role === 'OWN')?.user
      user && setUser(user)
      hunt && setHunt(hunt)
      huntOwner && setHuntOwner(huntOwner)
      user && setPhotos(user.documents)
      return
    }
    if (currentUser && huntId) {
      const hunt = currentUser?.hunts_as_invited.find(
        (hunt) => hunt.id === parseInt(huntId)
      )
      const huntOwner = hunt?.invited_users?.find(invitedUser => invitedUser.role === 'OWN')?.user
      hunt && setHunt(hunt)
      huntOwner && setHuntOwner(huntOwner)
      let pictures = []
      if (hunt?.domain.photos.length > 0) pictures = pictures.concat(hunt?.domain.photos)
      if (hunt?.photos.length > 0) pictures = pictures.concat(hunt.photos)
      hunt && setPhotos(pictures)
      return
    }
    if (currentUser && !hunt) {
      navigate('/app')
    }
  }, [currentUser])

  if (hunt && photos && huntOwner) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.return} onClick={onClickReturn}>
            <img src={returnIcon} />
          </div>
          <div className={styles['title-and-user-container']}>
            <div className={styles.title}>{hunt.name}</div>
            <div className={styles['user-container']}>
              <img src={organizerIcon} />
              {
                galleryMode === 'documents'
                  ? <div>
                      {currentUserIsHuntOwner && usersTexts.documents.yourDocuments}
                      {user && !currentUserIsHuntOwner && usersTexts.documents.documentsOf + ' : ' + capitalizeUserName(user)}
                    </div>
                  : <div>{huntsTexts.cards.organizer + ' : ' + formatUserName(
                    huntOwner,
                    currentUser
                  )}</div>
              }
            </div>
          </div>
        </div>
        <div className={styles['photos-container']}>
          {photos.map((photo, index) => {
            return (
              <div key={index} className={styles.photo}>
                {galleryMode === 'documents' && photo.type === 'LIC' && <div className={styles['document-label']} >{usersTexts.documents.licence}</div>}
                {galleryMode === 'documents' && photo.type === 'VAL' && <div className={styles['document-label']} >{usersTexts.documents.validation}</div>}
                {galleryMode === 'documents' && photo.type === 'INS' && <div className={styles['document-label']} >{usersTexts.documents.insurance}</div>}
                <img src={galleryMode === 'documents' ? photo.file : photo.photo} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default GalleryPage
