import React, { useState, useEffect } from 'react'
import { DatePicker, TimePicker } from 'antd'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'

// Components
import SectionTitle from 'components/UI/SectionTitle/SectionTitle'

// Helpers
import { formatDate } from 'helpers/dateFormat'

// Styles
import styles from './HuntFormDate.module.css'

// Icons
import dateAddIcon from 'assets/icons/common/dateAdd.svg'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'

const HuntFormDate = ({ handleFormChange, errors, setErrors, hunt }) => {
  const [inputValue, setInputValue] = useState()
  const dateFormat = 'YYYY-MM-DD'
  const timeFormat = 'HH:mm'
  const [time, setTime] = useState()
  const [date, setDate] = useState()
  const error = errors?.rendez_vous_datetime
  const defaultDate = hunt ? dayjs(formatDate({ format: dateFormat, date: hunt.rendez_vous_datetime }), dateFormat) : null
  const defaultTime = hunt ? dayjs(formatDate({ format: timeFormat, date: hunt.rendez_vous_datetime }), timeFormat) : null

  const disabledDate = (current) => {
    return current && current < dayjs().endOf('day')
  }

  const inputDateHandler = (date) => {
    setDate(date)
    setErrors({})
  }

  const inputTimeHandler = (time) => {
    setTime(time)
    setErrors({})
  }

  useEffect(() => {
    let newDate
    if (date || time) {
      if (date) {
        newDate = new Date(date)
      } else if (defaultDate) {
        newDate = new Date(defaultDate)
      } else {
        newDate = new Date()
      }
      if (time) {
        newDate.setHours(time.$H, time.$m, 0, 0)
      } else if (defaultDate) {
        newDate.setHours(defaultTime.$H, defaultTime.$m, 0, 0)
      } else {
        newDate.setHours(0, 0, 0, 0)
      }
      const newDateUtc = newDate.toISOString()
      setInputValue({ rendez_vous_datetime: newDateUtc })
    }
  }, [time, date])

  useEffect(() => {
    inputValue && handleFormChange(inputValue)
  }, [inputValue])

  return (
    <>
      <SectionTitle
        icon={dateAddIcon}
        textContent={huntsTexts.form.date.title}
      />
      <div className={styles['date-and-time-container']}>
        <DatePicker
          format={dateFormat}
          onChange={inputDateHandler}
          defaultValue={defaultDate || ''}
          disabledDate={disabledDate}
        />
        <TimePicker
          format={timeFormat}
          onChange={inputTimeHandler}
          minuteStep='15'
          defaultValue={defaultTime || ''}
        />
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </>
  )
}

HuntFormDate.propTypes = {
  handleFormChange: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  hunt: PropTypes.object
}

export default HuntFormDate
