import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// Store
import { uiActions } from 'store/ui-slice'
import { forgotUserPassword, loginUser, resetUserPassword, signupUser } from 'store/user-actions'

// Components
import Button from 'components/UI/Button/Button'
import Input from 'components/UI/Input/Input'

// Styles
import styles from './AuthenticationForm.module.css'

// Icons
import authenticationIcon from 'assets/icons/authentication/authentication.svg'
import authenticationAppLogo from 'assets/icons/authentication/authenticationAppLogo.svg'

// Texts
import authenticationTexts from 'assets/texts/authentication/fr.json'
import useNotifications from 'hooks/useNotifications'
import { formatPhoneRequest } from 'helpers/userValidations'

const AuthenticationForm = () => {
  const rawFormInputs = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: ''
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [formInputs, setFormInputs] = useState(rawFormInputs)
  const [isLoading, setIsLoading] = useState(false)
  const errors = useSelector((state) => state.ui.errors)
  const [searchParams] = useSearchParams()
  const resetPasswordMode = searchParams.get('token') !== null
  const loginMode = searchParams.get('mode') === 'login' || [...searchParams].length === 0
  const signupMode = searchParams.get('mode') === 'signup'
  const forgotPasswordMode = searchParams.get('mode') === 'forgot-password'
  const forgotPasswordModeSuccess = searchParams.get('mode') === 'forgot-password-success'
  const location = useLocation()
  const { openNotification, contextHolder } = useNotifications()
  let formStructure
  let title
  let actionContent
  if (resetPasswordMode) {
    formStructure = ['password', 'confirmPassword']
    title = authenticationTexts.resetPasswordAction
    actionContent = title
  } else if (forgotPasswordModeSuccess) {
    formStructure = []
    title = authenticationTexts.forgotPasswordSuccess
    actionContent = ''
  } else if (forgotPasswordMode) {
    formStructure = ['email']
    title = authenticationTexts.forgotPassword
    actionContent = authenticationTexts.forgotPasswordAction
  } else if (signupMode) {
    formStructure = Object.keys(rawFormInputs)
    title = authenticationTexts.signup
    actionContent = title
  } else if (loginMode) {
    formStructure = ['email', 'password']
    title = authenticationTexts.login
    actionContent = title
  }

  const inputChangeHandler = ({ currentTarget: { name, value } }) => {
    setFormInputs((previousState) => {
      return { ...previousState, [name]: value }
    })
  }

  const submitHandler = async (event) => {
    setIsLoading(true)
    event.preventDefault()
    const formattedEmail = formInputs.email.toLocaleLowerCase()
    if (forgotPasswordMode) {
      const success = await dispatch(forgotUserPassword({ email: formattedEmail }))
      if (success) {
        navigate('?mode=forgot-password-success')
      }
      setIsLoading(false)
      return
    }
    if (loginMode) {
      await dispatch(
        loginUser({ email: formattedEmail, password: formInputs.password })
      )
      setIsLoading(false)
      return
    }
    if (formInputs.password !== formInputs.confirmPassword) {
      await dispatch(
        uiActions.setErrors({ confirmPassword: ['Both passwords differs'] })
      )
      setIsLoading(false)
      return
    }
    if (resetPasswordMode) {
      const success = await dispatch(resetUserPassword({ password: formInputs.password, token: searchParams.get('token') }))
      if (success) {
        navigate('?mode=login')
        openNotification({
          message: authenticationTexts.resetPasswordSuccessMessage,
          description: authenticationTexts.resetPasswordSuccessDescription,
          className: 'notification-success'
        })
      }
      setIsLoading(false)
      return
    }
    await dispatch(
      signupUser({
        email: formattedEmail,
        password: formInputs.password,
        first_name: formInputs.firstname,
        last_name: formInputs.lastname,
        phone: formatPhoneRequest(formInputs.phone)
      })
    )
    setIsLoading(false)
  }

  return (
    <>
      {contextHolder}
      <form onSubmit={submitHandler} className={styles.form}>
        <div className={styles['authentication-image-container']}>
          <img src={authenticationIcon} />
        </div>
        <div className={styles.title}>
          <h1>
            {title}
          </h1>
        </div>
        <div className={styles.container}>
          <div className={styles.inputs}>
            {formStructure.map((inputType) => {
              return (
                <Input
                  key={inputType}
                  id={inputType}
                  type={
                    inputType === 'confirmPassword' ? 'password' : inputType
                  }
                  name={inputType}
                  htmlFor={inputType}
                  labelText={authenticationTexts.form[inputType]}
                  placeholder={authenticationTexts.placeholders[inputType]}
                  onChange={inputChangeHandler}
                  value={formInputs[inputType]}
                  required={true}
                  errors={errors}
                />
              )
            })}
          </div>
          {(loginMode) && (
              <Link to='?mode=forgot-password'>
                <div className={styles['forgot-password-container']}>
                  <p>
                    {authenticationTexts.forgotPassword}
                  </p>
                </div>
              </Link>
          )}
          {actionContent && (
              <div className={styles.actions}>
                <Button
                    type='submit'
                    content={actionContent}
                    className='call-to-action'
                    loading={isLoading}
                />
              </div>
          )}
          <Link
            to={`?mode=${signupMode || forgotPasswordMode || forgotPasswordModeSuccess ? 'login' : 'signup'}`}
            onClick={() => dispatch(uiActions.resetErrors())}
            state={location.state}
          >
            <div className={styles['toggle-mode-container']}>
              <p>
                {signupMode || forgotPasswordMode || forgotPasswordModeSuccess
                  ? authenticationTexts.form.links.loginMessage
                  : authenticationTexts.form.links.signupMessage}
              </p>
                {signupMode || forgotPasswordMode || forgotPasswordModeSuccess
                  ? authenticationTexts.login
                  : authenticationTexts.signup}
            </div>
          </Link>
          <div className={styles['authentication-app-logo-container']}>
            <img src={authenticationAppLogo} />
          </div>
        </div>
      </form>
    </>
  )
}

export default AuthenticationForm
