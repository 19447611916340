import axios from 'axios'
import authenticationAPI from './authenticationAPI'
import { logout } from 'helpers/authentication'

const setAxiosToken = (token) => {
  if (token) {
    axios.defaults.headers['Authorization'] = 'Bearer ' + token
  }

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      const originalRequest = error.config
      const currentRefreshToken = localStorage.getItem('refreshToken')
      // If the token has expired, get a new one (thanks to refresh token) and do the request again (originalRequest with the new token)
      if (
        error.response &&
        error.response.status === 401 &&
        error.config &&
        !error.config.__isRetryRequest &&
        currentRefreshToken
      ) {
        try {
          originalRequest._retry = true
          const currentRefreshToken = localStorage.getItem('refreshToken')
          const { access, refresh } = await authenticationAPI.refreshToken(
            currentRefreshToken
          )
          localStorage.setItem('token', access)
          localStorage.setItem('refreshToken', refresh)
          originalRequest.headers['Authorization'] = 'Bearer ' + access
          return axios(originalRequest)
        } catch (error) {
          console.log(error)
          logout()
        }
      }

      return Promise.reject(error)
    }
  )
}

export default {
  setAxiosToken
}
