import React from 'react'

// Components
import UserForm from 'components/User/UserForm/UserForm'

// Styles
import styles from './UserProfilePage.module.css'
import Header from 'components/UI/Header/Header'

// Texts
import userTexts from 'assets/texts/user/fr.json'

const UserProfilePage = () => {
  return (
    <div className={styles.container}>
      <Header
        titleText={userTexts.profile.title}
        withReturn={true}
      >
        <div className={styles.wrapper} >
          <div className={styles.content}>
            <UserForm />
          </div>
        </div>
      </Header>
    </div>
  )
}

export default UserProfilePage
