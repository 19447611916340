import React from 'react'
import PropTypes from 'prop-types'
import styles from './Animal.module.css'
import Species from './Species/Species'
import huntsTexts from 'assets/texts/hunts/fr.json'
const Animal = ({ species, quota, ring, color }) => {
  return (
    <div className={styles.container}>
        <div className={styles['species-container']}>
            <Species color={color} species={species} withInfo={true}/>
        </div>
        <div>
            <div className={styles.quota}>{quota && `${huntsTexts.cards.huntPlan.quotaLabel} : ${quota}`}</div>
            <div className={styles.ring}>{ring && `${huntsTexts.cards.huntPlan.ringLabel} : ${ring}`}</div>
        </div>
    </div>
  )
}

Animal.propTypes = {
  species: PropTypes.string,
  quota: PropTypes.number,
  ring: PropTypes.string,
  color: PropTypes.string
}
export default Animal
