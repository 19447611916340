import styles from './HuntingPlan.module.css'
import huntsTexts from 'assets/texts/hunts/fr.json'
import React from 'react'
import PropTypes from 'prop-types'
import Animal from './Animal/Animal'
import Species from './Animal/Species/Species'
import { huntingPlanColors } from './HuntingPlanColors'
import { huntingPlanComparison } from './HuntingPlanComparison'

const HuntingPlan = ({ hunt, withInfo }) => {
  const huntingPlanSorted = [...hunt.hunting_plan].sort(huntingPlanComparison)
  const huntingPlanDisplayable = (hunt) => {
    const twoHoursInMilliseconds = 1000 * 60 * 60 * 2
    const dateDiffToHunt = new Date(hunt.rendez_vous_datetime) - new Date()
    return dateDiffToHunt < twoHoursInMilliseconds
  }
  if (withInfo) {
    return (
      huntingPlanDisplayable(hunt)
        ? (
          <div className={styles['hunt-plan']}>
            <p className={styles['section-title']}>{huntsTexts.cards.huntPlan.sectionTitle}</p>
            {huntingPlanSorted.map((plan, index) => (
              <Animal
                key={plan.id}
                species={plan.animal}
                quota={plan.quota}
                ring={plan.ring}
                color={huntingPlanColors[index % huntingPlanColors.length]}
              />
            ))}
          </div>
          )
        : (
          <div className={styles['hunt-plan']}>
            <p className={styles['section-title']}>{huntsTexts.cards.huntPlan.sectionTitle}</p>
            <p className={styles.warning}>{huntsTexts.form.plan.warning}</p>
          </div>
          )
    )
  } else {
    return (
      huntingPlanDisplayable(hunt) &&
      (
        <div className={styles.container}>
          {huntingPlanSorted.map((plan, index) => (
            <Species
              key={plan.id}
              species={plan.animal}
              color={huntingPlanColors[index % huntingPlanColors.length]}
              withInfo={false}
            />
          ))}
        </div>
      )
    )
  }
}
HuntingPlan.propTypes = {
  hunt: PropTypes.object,
  withInfo: PropTypes.bool
}
export default HuntingPlan
