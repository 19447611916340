import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

// Store
import { fetchCurrentUser } from 'store/user-actions'

// Services
import huntsAPI from 'services/huntsAPI'

// Components
import Button from 'components/UI/Button/Button'
import Footer from 'components/UI/Footer/Footer'
import HuntFormAbout from './HuntFormAbout/HuntFormAbout'
import HuntFormDomain from './HuntFormDomain/HuntFormDomain'
import HuntFormDate from './HuntFormDate/HuntFormDate'
import HuntFormPlan from './HuntFormPlan/HuntFormPlan'
import HuntFormInfo from './HuntFormInfo/HuntFormInfo'
import Modal from 'components/UI/Modal/Modal'

// Custom hooks
import useNotifications from 'hooks/useNotifications'

// Helpers
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'

// Styles
import styles from './HuntForm.module.css'

// Icons
import huntsFooterIcon from 'assets/icons/hunts/huntsFooterIcon.svg'
import infoIcon from 'assets/icons/common/info.svg'
import securityIcon from 'assets/icons/common/security.svg'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'

const HuntForm = ({ currentUser, domain, hunt }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [formInputs, setFormInputs] = useState()
  const [hasErrors, setHasErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const { openNotification, contextHolder } = useNotifications()

  const handleFormChange = (data) => {
    setHasChanged(true)
    setFormInputs((previousState) => {
      return { ...previousState, ...data }
    })
  }

  const formHasErrors = () => {
    let hasError = false
    if (!formInputs?.domain) {
      if (!hunt || !hunt.domain) {
        setErrors((previousState) => {
          return {
            ...previousState,
            domain: huntsTexts.form.domain.error
          }
        })
        setHasErrors(true)
        hasError = true
      }
    }
    if (!formInputs?.rendez_vous_datetime) {
      if (!hunt || !hunt.rendez_vous_datetime) {
        setErrors((previousState) => {
          return {
            ...previousState,
            rendez_vous_datetime: huntsTexts.form.date.error
          }
        })
        setHasErrors(true)
        hasError = true
      }
    }
    const noPlansErrors = formInputs?.hunting_plan
      ?.filter((plan) => !plan.animal)
      ?.map((error) => {
        return { id: error.id, message: huntsTexts.form.plan.animal.error }
      })
    if (noPlansErrors?.length > 0) {
      setErrors((previousState) => {
        return {
          ...previousState,
          plan: noPlansErrors
        }
      })
      setHasErrors(true)
      hasError = true
    }
    return hasError
  }

  const handleCreate = async () => {
    const response = await huntsAPI.createHunt(formInputs)
    if (response.status === 201) {
      dispatch(fetchCurrentUser(getAuthTokenFromLocalStorage()))
      navigate(`/app/hunt/${response.data.id}`, { state: { type: 'hunt', create: 'success' } })
    } else {
      openNotification({
        message: huntsTexts.form.create.notifications.error.title,
        className: 'notification-error'
      })
    }
  }

  const handleUpdate = async () => {
    const response = await huntsAPI.updateHunt({
      id: hunt.id,
      data: formInputs
    })
    if (response.status === 200) {
      dispatch(fetchCurrentUser(getAuthTokenFromLocalStorage()))
      setFormInputs(null)
      setHasChanged(false)
      openNotification({
        message: huntsTexts.form.update.notifications.success.title,
        description: huntsTexts.form.update.notifications.success.message,
        className: 'notification-success'
      })
    } else {
      openNotification({
        message: huntsTexts.form.create.notifications.error.title,
        className: 'notification-error'
      })
    }
  }

  const handleDelete = async () => {
    setIsDeleteLoading(true)
    try {
      await huntsAPI.deleteHunt(hunt.id)
      navigate('/app', { state: { type: 'hunt', delete: 'success' } })
    } catch (error) {
      openNotification({
        message: huntsTexts.form.delete.notifications.error.title,
        className: 'notification-error'
      })
    }
    setIsDeleteLoading(false)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    if (formHasErrors()) {
      setIsLoading(false)
      return
    } else {
      if (hunt) {
        await handleUpdate()
      } else {
        await handleCreate()
      }
    }
    setIsLoading(false)
  }

  const deleteModal = (
    <Modal
      title={huntsTexts.form.delete.modal.title}
      message={huntsTexts.form.delete.modal.message}
      confirmText={huntsTexts.form.delete.modal.confirmText}
      cancelText={huntsTexts.form.delete.modal.cancelText}
      onConfirm={handleDelete}
      onClose={() => setModalIsOpen(false)}
      isLoading={isDeleteLoading}
    />
  )

  const submitButton = (
    <Button
      type='button'
      content={
        hunt
          ? huntsTexts.form.update.callToAction
          : huntsTexts.form.create.callToAction
      }
      className='call-to-action'
      onClick={handleSubmit}
      loading={isLoading}
    />
  )

  const deleteButton = (
    <Button
      type='button'
      content={huntsTexts.form.delete.callToAction}
      className='red'
      onClick={() => setModalIsOpen(true)}
    />
  )

  useEffect(() => {
    if (hasErrors) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setHasErrors(false)
    }
  }, [hasErrors])

  if (currentUser) {
    return (
      <>
        <>{contextHolder}</>
        <>{modalIsOpen && deleteModal}</>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <HuntFormAbout handleFormChange={handleFormChange} hunt={hunt} />
            <HuntFormDomain
              domains={currentUser.domains}
              domain={domain}
              hunt={hunt}
              handleFormChange={handleFormChange}
              errors={errors}
              setErrors={setErrors}
            />
            <HuntFormDate
              handleFormChange={handleFormChange}
              errors={errors}
              setErrors={setErrors}
              hunt={hunt}
            />
            <HuntFormPlan
              handleFormChange={handleFormChange}
              errors={errors}
              setErrors={setErrors}
              hunt={hunt}
            />
            <HuntFormInfo
              handleFormChange={handleFormChange}
              hunt={hunt}
              field={'security_free_text'}
              icon={securityIcon}
            />
            <HuntFormInfo
              handleFormChange={handleFormChange}
              hunt={hunt}
              field={'organization_free_text'}
              icon={infoIcon}
            />
            {!hunt ? submitButton : hasChanged ? submitButton : null}
            {hunt && deleteButton}
            <div className={styles.footer}>
              <img src={huntsFooterIcon} />
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

HuntForm.propTypes = {
  currentUser: PropTypes.object,
  domain: PropTypes.object,
  hunt: PropTypes.object
}

export default HuntForm
