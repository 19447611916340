import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

// Actions
import { fetchCurrentUser } from 'store/user-actions'

// Services
import domainsAPI from 'services/domainsAPI'

// Custom hooks
import useNotifications from 'hooks/useNotifications'

// Components
import Button from 'components/UI/Button/Button'
import DomainFormAbout from './DomainFormAbout/DomainFormAbout'
import DomainFormPictures from './DomainFormPictures/DomainFormPictures'
import DomainFormPlan from './DomainFormPlan/DomainFormPlan'
import Footer from 'components/UI/Footer/Footer'

// Helpers
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'

// Styles
import styles from './DomainForm.module.css'

// Icons
import domainsFooter from 'assets/icons/domains/domainsFooter.svg'

// Texts
import domainsTexts from 'assets/texts/domains/fr.json'
import Modal from 'components/UI/Modal/Modal'

const DomainForm = ({ domain }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [formInputs, setFormInputs] = useState({})
  const [hasErrors, setHasErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [errors, setErrors] = useState([])
  const { openNotification, contextHolder } = useNotifications()

  const formChangeHandler = (newState) => {
    setFormInputs((previousState) => {
      return { ...previousState, ...newState }
    })
  }

  const formHasErrors = () => {
    let hasError = false
    if (!formInputs.name) {
      setErrors((previousState) => [
        ...previousState,
        { name: domainsTexts.form.errors.name }
      ])
      setHasErrors(true)
      hasError = true
    }
    if (!formInputs.address) {
      setErrors((previousState) => [
        ...previousState,
        { address: domainsTexts.form.errors.address }
      ])
      setHasErrors(true)
      hasError = true
    }
    return hasError
  }

  const handleCreate = async () => {
    const response = await domainsAPI.createDomain(formInputs)
    if (response.status === 201) {
      dispatch(fetchCurrentUser(getAuthTokenFromLocalStorage()))
      navigate('/app', { state: { type: 'domain', create: 'success' } })
    } else {
      openNotification({
        message: domainsTexts.form.create.notifications.error.title,
        description: '',
        className: 'notification-error'
      })
    }
  }

  const handleUpdate = async () => {
    const response = await domainsAPI.updateDomain({
      id: domain.id,
      data: formInputs
    })
    if (response.status === 200) {
      dispatch(fetchCurrentUser(getAuthTokenFromLocalStorage()))
      openNotification({
        message: domainsTexts.form.update.notifications.success.title,
        description: domainsTexts.form.update.notifications.success.message,
        className: 'notification-success'
      })
    } else {
      openNotification({
        message: domainsTexts.form.update.notifications.error.title,
        description: '',
        className: 'notification-error'
      })
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    if (formHasErrors()) {
      setIsLoading(false)
      return
    }
    if (domain) {
      await handleUpdate()
    } else {
      await handleCreate()
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (hasErrors) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setHasErrors(false)
    }
  }, [hasErrors])

  const handleDelete = async () => {
    setIsDeleteLoading(true)
    try {
      await domainsAPI.deleteDomain(domain.id)
      navigate('/app', { state: { type: 'domain', delete: 'success' } })
    } catch (error) {
      openNotification({
        message: domainsTexts.form.delete.notifications.error.title,
        className: 'notification-error'
      })
    }
    setIsDeleteLoading(false)
  }

  const deleteButton = (
    <Button
        type='button'
        content={domainsTexts.form.delete.callToAction}
        className='red'
        onClick={() => setModalIsOpen(true)}
    />
  )

  const submitButton = (
    <Button
      type='button'
      content={
        domain
          ? domainsTexts.form.update.callToAction
          : domainsTexts.form.create.callToAction
      }
      className='call-to-action'
      onClick={handleSubmit}
      loading={isLoading}
    />
  )

  const deleteModal = (
    <Modal
      title={domainsTexts.form.delete.modal.title}
      message={domainsTexts.form.delete.modal.message}
      confirmText={domainsTexts.form.delete.modal.confirmText}
      cancelText={domainsTexts.form.delete.modal.cancelText}
      onConfirm={handleDelete}
      onClose={() => setModalIsOpen(false)}
      isLoading={isDeleteLoading}
    />
  )

  return (
    <>
      <>{contextHolder}</>
      <>{modalIsOpen && deleteModal}</>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <DomainFormAbout
            formChangeHandler={formChangeHandler}
            errors={errors}
            setErrors={setErrors}
            domain={domain}
          />
          <DomainFormPictures
            formChangeHandler={formChangeHandler}
            domain={domain}
          />
          <DomainFormPlan
            formChangeHandler={formChangeHandler}
            domain={domain}
          />
          <div className={styles['submit-button-container']}>
            {submitButton}
            {domain && deleteButton}
          </div>
        </div>
        <div className={styles.icon}>
          <img src={domainsFooter} />
        </div>
      </div>
      <Footer />
    </>
  )
}

DomainForm.propTypes = {
  domain: PropTypes.object
}

export default DomainForm
