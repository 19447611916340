import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import Input from 'components/UI/Input/Input'

// Styles
import styles from './HuntFormSpecies.module.css'

// Icons
import closeIcon from 'assets/icons/common/close.svg'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'

const HuntFormSpecies = ({
  plan,
  count,
  handlePlanChange,
  handleRemoveSpecies,
  errors,
  setErrors
}) => {
  const [inputsValues, setInputsValues] = useState()
  const noPlanError = errors?.plan?.find((error) => error.id === plan.id)

  const inputChangeHandler = ({ currentTarget: { name, value } }) => {
    const newInputValues = {
      id: plan.id,
      animal: name === 'animal' ? value : plan.animal ? plan.animal : '',
      quota: name === 'quota' ? parseInt(value) : plan.quota ? plan.quota : 0,
      ring: name === 'ring' ? value : plan.ring ? plan.ring : ''
    }
    if (newInputValues.quota === '' || newInputValues.quota === 0) {
      delete newInputValues.quota
    }
    setInputsValues(newInputValues)
  }

  const handleClick = (event) => {
    if (event.target.id === 'animal') {
      setErrors({})
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    inputsValues && handlePlanChange(inputsValues)
  }, [inputsValues])

  return (
    <>
      <div className={styles.title}>
        <div
          className={styles['title-text']}
        >{`${huntsTexts.form.plan.species} ${count}`}</div>
        <div
          className={styles.close}
          onClick={() => handleRemoveSpecies(plan.id)}
        >
          <img src={closeIcon} />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles['animal-and-quota-container']}>
          <div className={styles['animal-container']}>
            <Input
              id='animal'
              type='text'
              name='animal'
              htmlFor='animal'
              className={styles.animal}
              labelText={huntsTexts.form.plan.animal.label}
              placeholder={huntsTexts.form.plan.animal.placeholder}
              onChange={inputChangeHandler}
              value={inputsValues?.animal ? inputsValues.animal : plan?.animal ? plan.animal : ''}
            />
            {noPlanError && <div className={styles.error}>{noPlanError.message}</div>}
          </div>
          <div className={styles['quota-container']}>
            <Input
              id='quota'
              type='number'
              name='quota'
              htmlFor='quota'
              className={styles.quota}
              labelText={huntsTexts.form.plan.quota.label}
              placeholder={huntsTexts.form.plan.quota.placeholder}
              onChange={inputChangeHandler}
              value={
                inputsValues?.quota > 0 ? parseInt(inputsValues.quota) : plan?.quota ? plan.quota : ''
              }
              min='0'
            />
          </div>
        </div>
        <Input
          id='ring'
          type='text'
          name='ring'
          htmlFor='ring'
          className={styles.ring}
          labelText={huntsTexts.form.plan.ring.label}
          placeholder={huntsTexts.form.plan.ring.placeholder}
          onChange={inputChangeHandler}
          value={inputsValues?.ring ? inputsValues.ring : plan ? plan.ring : ''}
        />
      </div>
    </>
  )
}

HuntFormSpecies.propTypes = {
  plan: PropTypes.object,
  count: PropTypes.number,
  handlePlanChange: PropTypes.func,
  handleRemoveSpecies: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func
}

export default HuntFormSpecies
