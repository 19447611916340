const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}
export const formatUserName = (user, currentUser) => {
  if (!user) return ''
  if (currentUser && user.email && currentUser.email && user.email === currentUser.email) return 'Vous'
  return `${capitalize(user.first_name)} ${capitalize(user.last_name.charAt(0))}.`
}

export const capitalizeUserName = (user, currentUser, strict = false) => {
  if (!user) return ''
  if (!strict && currentUser && user.email && currentUser.email && user.email === currentUser.email) return 'Vous'
  return `${capitalize(user.first_name)} ${capitalize(user.last_name)}`
}

export const formatUserNameToInitials = (user) => {
  if (!user) return ''
  return `${capitalize(user.first_name.charAt(0))}${capitalize(user.last_name.charAt(0))}`
}

export const capitalizeAllWords = (string) => {
  if (!string) return string
  return string.split(' ').map((word) => capitalize(word)).join(' ')
}
