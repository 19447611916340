import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'

// Components
import Avatar from 'components/User/Avatar/Avatar'
import NotUpToDate from 'components/UI/Notifications/NotUpToDate/NotUpToDate'

// Helpers
import { formatDateToFrenchDate } from 'helpers/dateFormat'

// Styles
import styles from './Header.module.css'

// Icons
import returnIcon from 'assets/icons/common/return.svg'
import appIcon from 'assets/icons/common/appIcon.svg'
import Button from 'components/UI/Button/Button'

const Header = ({
  date,
  withReturn,
  withAvatar,
  titleText,
  paragraphText,
  children,
  displayNotUpToDate,
  withPhotos,
  withWhiteContainer,
  signInButtonContent,
  withNavigation = true,
  withWelcome = true
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const navigateToApp = () => navigate('/auth?mode=signup')
  const formattedDate = date
    ? formatDateToFrenchDate(new Date(date), {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
    : ''
  return (
    <div
      className={
        `${styles.container}
        ${withWhiteContainer ? styles['with-white-container'] : ''}
        ${withReturn ? styles['with-return-container'] : ''}
        ${displayNotUpToDate ? styles['not-up-to-date'] : ''}
        ${location.pathname === '/user' ? styles['user-page'] : ''}
        ${location.pathname === '/app' ? styles['home-page'] : ''}
        ${withPhotos ? styles['with-photos-container'] : ''}
        ${!withNavigation ? styles['without-navigation'] : ''}
        `
      }>
      <div className={styles.content} >
        <div className={styles['content-and-up-to-date-container']} >
          <div className={
              `${withNavigation ? styles['title-and-avatar-container'] : styles['no-title-and-avatar-container']} 
              ${withPhotos ? styles['with-photos'] : ''}`
          } >
            <div className={styles['return-and-title-container']} >
              {withReturn && (
                <div className={styles.return} onClick={() => navigate(-1)}>
                  <img src={returnIcon} />
                </div>
              )}
                {withWelcome
                  ? (
                  <div className={styles['title-and-date-container']}>
                    <h1>{titleText}</h1>
                    <p
                      className={styles.date}
                    >{paragraphText ? `${paragraphText} ${formattedDate}` : `${formattedDate}`} </p>
                  </div>
                    )
                  : (
                    <div className={styles['header-icon']}>
                        <img src={appIcon} className={styles['app-icon']} />
                    </div>
                    )}
                {signInButtonContent && (
                    <div className={styles.signin}>
                        <Button
                            content={signInButtonContent}
                            className='inversed-call-to-action'
                            onClick={navigateToApp}
                        />
                    </div>
                )}
            </div>
            {
              withAvatar && <Avatar />
            }
          </div>
          {
            displayNotUpToDate && <NotUpToDate withPhotos={withPhotos} />
          }
        </div>
      </div>
      {children}
    </div>
  )
}

Header.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  titleText: PropTypes.string,
  paragraphText: PropTypes.string,
  withReturn: PropTypes.bool,
  withAvatar: PropTypes.bool,
  displayNotUpToDate: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  withPhotos: PropTypes.bool,
  withWhiteContainer: PropTypes.bool,
  withNavigation: PropTypes.bool,
  withWelcome: PropTypes.bool,
  signInButtonContent: PropTypes.string
}

export default Header
