import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

// Store
import { fetchCurrentUser } from 'store/user-actions'

// Components
import DomainsOverlay from 'components/Domains/DomainsOverlay/DomainsOverlay'
import Footer from 'components/UI/Footer/Footer'
import Header from 'components/UI/Header/Header'
import HuntsOverlay from 'components/Hunts/HuntsOverlay/HuntsOverlay'

// Helpers
import { addOneDayToDate } from 'helpers/dateFormat'
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'
import { displayNotUpToDate } from 'helpers/userValidations'

// Styles
import styles from './HomePage.module.css'

// Texts
import commonTexts from 'assets/texts/common/fr.json'
import domainsText from 'assets/texts/domains/fr.json'
import huntsTexts from 'assets/texts/hunts/fr.json'
import useNotifications from 'hooks/useNotifications'

const HomePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const token = getAuthTokenFromLocalStorage()
  const error = useSelector((state) => state.ui.error)
  const currentUser = useSelector((state) => state.user.currentUser)
  const navigateToHuntForm = (id) =>
    navigate('/app/hunt-form/create', { state: { domainId: id } })
  const navigateToDomainForm = (id) => navigate(`/app/domain-form/${id}`)
  const date = new Date()
  const hunts = currentUser?.hunts_as_invited.filter(
    (hunt) => addOneDayToDate(new Date(hunt.rendez_vous_datetime)) > new Date()
  )
  const { openNotification, contextHolder } = useNotifications()

  useEffect(() => {
    dispatch(fetchCurrentUser(token))
  }, [])

  useEffect(() => {
    if (
      location?.state?.type === 'invite' &&
      location?.state?.cancelInvite === 'success'
    ) {
      openNotification({
        message: huntsTexts.actions.invite.notifications.title,
        className: 'notification-success'
      })
    }
  }, [location])

  const handleDisplayDetails = (id) => navigate(`/app/hunt/${id}`)

  const huntActions = [
    { text: commonTexts.actions.findOutMore, action: handleDisplayDetails }
  ]

  const domainActions = [
    {
      text: domainsText.form.update.callToAction,
      action: navigateToDomainForm
    },
    { text: huntsTexts.actions.create, action: navigateToHuntForm }
  ]

  if (currentUser) {
    return (
      <>
        <>{contextHolder}</>
        <Header
          titleText={`${commonTexts.greetings} ${currentUser.first_name}`}
          date={date}
          withAvatar={true}
          withWhiteContainer={true}
          displayNotUpToDate={displayNotUpToDate(currentUser)}
        >
          <div className={styles.content}>
            <HuntsOverlay hunts={hunts} actions={huntActions} />
            <DomainsOverlay
              domains={currentUser.domains}
              actions={domainActions}
            />
            <Footer />
          </div>
        </Header>
      </>
    )
  } else if (error) {
    return <p>{error}</p>
  }
}

export default HomePage
