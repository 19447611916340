import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './CardsButtons.module.css'
import huntsTexts from 'assets/texts/hunts/fr.json'
import Modal from 'components/UI/Modal/Modal'

const CardsButtons = ({ actions = [], id, withPhotos }) => {
  const [isOpen, setIsOpen] = useState()
  const [isLoading, setIsLoading] = useState()
  return (
    <div className={`${styles.container} ${withPhotos ? styles['with-photos'] : ''}`}>
      {actions.length === 1 && actions[0].modal && isOpen && (<Modal
        title={huntsTexts.actions.confirmParticipation.modal.title}
        message={huntsTexts.actions.confirmParticipation.modal.message}
        confirmText={huntsTexts.actions.confirmParticipation.modal.confirmText}
        cancelText={huntsTexts.actions.confirmParticipation.modal.cancelText}
        onConfirm={() => {
          setIsLoading(true)
          actions[0].action(id)
        }}
        onClose={() => setIsOpen(false)}
        isLoading={isLoading}
      />)}
      {actions.map((action, index) => {
        const first = index === 0
        return (
          <div
            className={first ? styles.first : styles.second}
            key={index}
            onClick={() => action.modal ? setIsOpen(true) : action.action(id)}
          >
            <div>
              {action.icon && <img src={action.icon} />}
              <p>{action.text}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

CardsButtons.propTypes = {
  actions: PropTypes.array,
  id: PropTypes.number,
  withPhotos: PropTypes.bool
}

export default CardsButtons
