import React from 'react'
import PropTypes from 'prop-types'

const Select = ({
  defaultOptionText,
  defaultOptionValue,
  htmlFor,
  id,
  labelText,
  name,
  onChange,
  options
}) => {
  return (
    <>
      <label htmlFor={htmlFor}>{labelText}</label>
      <select
        name={name}
        id={id}
        onChange={onChange}
        defaultValue={defaultOptionValue}
      >
        {<option value=''>{defaultOptionText}</option>}
        {options.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          )
        })}
      </select>
    </>
  )
}

Select.propTypes = {
  defaultOptionText: PropTypes.string,
  defaultOptionValue: PropTypes.number,
  htmlFor: PropTypes.string,
  id: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array
}

export default Select
