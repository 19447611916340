import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import Input from 'components/UI/Input/Input'
import SectionTitle from 'components/UI/SectionTitle/SectionTitle'

// Styles
import styles from './DomainFormAbout.module.css'

// Icons
import infoIcon from 'assets/icons/common/info.svg'

// Texts
import domainsTexts from 'assets/texts/domains/fr.json'
import AddressAutocompleteInput from 'components/UI/AddressAutocompleteInput/AddressAutocompleteInput'

const DomainFormAbout = ({ formChangeHandler, errors, setErrors, domain }) => {
  const [formInputs, setFormInputs] = useState({
    name: '',
    address: '',
    city: '',
    latitude: '',
    longitude: ''
  })
  const formStructure = Object.keys(formInputs)
  const nameFieldError = errors?.find((error) => error.name)?.name
  const addressFieldError = errors?.find((error) => error.address)?.address

  const inputChangeHandler = ({ currentTarget: { name, value } }) => {
    setFormInputs((previousState) => {
      return { ...previousState, [name]: value }
    })
  }

  const addressChangeHandler = (address, city, latitude, longitude) => {
    setFormInputs((previousState) => {
      return { ...previousState, address, city, latitude, longitude }
    })
  }

  useEffect(() => {
    formChangeHandler(formInputs)
  }, [formInputs])

  useEffect(() => {
    if (domain) {
      setFormInputs({
        name: domain?.name ? domain.name : '',
        address: domain?.address ? domain.address : '',
        city: domain?.city ? domain.city : '',
        latitude: domain?.latitude ? domain.latitude : '',
        longitude: domain?.longitude ? domain.longitude : ''
      })
    }
  }, [domain])

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  const handleClick = (event) => {
    if (event.target.type === 'text') {
      setErrors([])
    }
  }

  return (
    <div className={styles.container}>
      <SectionTitle
        icon={infoIcon}
        textContent={domainsTexts.form.about.title}
      />
      <Input
        id={formStructure[0]}
        type={formStructure[0]}
        name={formStructure[0]}
        htmlFor={formStructure[0]}
        className={formStructure[0]}
        labelText={domainsTexts.form.about.inputs[formStructure[0]]}
        onChange={inputChangeHandler}
        value={formInputs[formStructure[0]]}
        required={true}
      />
      {nameFieldError && (
        <div className={styles.error}>
          {nameFieldError}
        </div>
      )}
      <AddressAutocompleteInput
        htmlFor={formStructure[1]}
        labelClassname={formStructure[1]}
        labelText={domainsTexts.form.about.inputs[formStructure[1]]}
        onSelected={addressChangeHandler}
        address={formInputs?.address}
      />
      {addressFieldError && (
        <div className={styles.error}>
          {addressFieldError}
        </div>
      )}
    </div>
  )
}

DomainFormAbout.propTypes = {
  formChangeHandler: PropTypes.func,
  errors: PropTypes.array,
  setErrors: PropTypes.func,
  domain: PropTypes.object
}

export default DomainFormAbout
