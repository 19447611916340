import React from 'react'
import Header from 'components/UI/Header/Header'
import styles from './PlanTutorialPage.module.css'
import annotations from 'assets/images/tutorial/annotations.jpg'
import geoportailImage from 'assets/images/tutorial/geoportail.jpg'
import addressFilledImage from 'assets/images/tutorial/addressFill.jpg'
import satelliteImage from 'assets/images/tutorial/satellite.jpg'
import satelliteCenteredImage from 'assets/images/tutorial/satelliteCentered.jpg'
import graphicTools from 'assets/images/tutorial/graphicTools.jpg'
import PlanAnnotation from 'assets/images/tutorial/PlanAnnotation.jpg'
import drawPolygones from 'assets/images/tutorial/drawPolygones.jpg'
import finishDrawPoly from 'assets/images/tutorial/finishDrawPoly.jpg'
import drawPolyFinish2 from 'assets/images/tutorial/drawPolyFinish2.jpg'
import changeLayout from 'assets/images/tutorial/changeLayout.jpg'
import ScreenShot from 'assets/images/tutorial/ScreenShot.jpg'
import screenshotResult from 'assets/images/tutorial/screenshotResult.jpg'
import selectLayout from 'assets/images/tutorial/selectLayout.jpg'
import selectLayoutIGN from 'assets/images/tutorial/selectLayoutIGN.jpg'
import startDrawPoly from 'assets/images/tutorial/startDrawPoly.jpg'
import writeAnnotation from 'assets/images/tutorial/writeAnnotation.jpg'
import { Link } from 'react-router-dom'
import Footer from 'components/UI/Footer/Footer'

const PlanTutorialPage = () => {
  return (
    <Header
        titleText='Réaliser les plans de votre domaine'
        withReturn={false}
        withPhotos={true}
    >
      <div className={styles.container}>
        <p className={styles.content}>Rendez-vous sur le site <a href='https://www.geoportail.gouv.fr/' target='_blank' rel="noreferrer">Geoportail</a></p>
        <img alt='image' className={styles.contentImage} src={geoportailImage}/>
        <p className={styles.content}>{`${'Renseignez l\'adresse de votre domaine (nous prendrons le domaine du Joinchet 41220 comme exemple) :'}`} </p>
        <img alt='image' className={styles.contentImage} src={addressFilledImage}/>
        <p className={styles.content}>Vous arrivez sur une image satellite centrée sur votre domaine : </p>
        <img alt='image' className={styles.contentImage} src={satelliteImage}/>
        <p className={styles.content}>
          Déplacez en maintenant clic droit de la souris et
          ajustez le zoom avec les bouton en haut à gauche
          de manière à voir la parcelle que vous souhaitez délimiter :
        </p>
        <img alt='image' className={styles.contentImage} src={satelliteCenteredImage}/>
        <p className={styles.content}>
          Vous pouvez afficher la carte IGN de la zone si vous le souhaitez en cliquant sur le bouton en haut à droite,
          puis en cliquant sur <b>plus de données</b> puis sur <b>Plan IGN</b> :
        </p>
        <img alt='image' className={styles.contentImage} src={changeLayout}/>
        <img alt='image' className={styles.contentImage} src={selectLayout}/>
        <img alt='image' className={styles.contentImage} src={selectLayoutIGN}/>
        <p className={styles.content}>
          {`${'Pour notre exemple, nous garderons la carte satellite. Cliquez sur l\'option d\'outils graphiques '}`}
          puis sur <b>annoter la carte</b> puis sur <b>Dessiner des polygones</b> :
        </p>
        <img alt='image' className={styles.contentImage} src={graphicTools}/>
        <img alt='image' className={styles.contentImage} src={PlanAnnotation}/>
        <img alt='image' className={styles.contentImage} src={drawPolygones}/>
        <p className={styles.content}>
          Vous pouvez commencer à délimiter votre parcelle. Cliquez pour créer un point,
          puis déplacer la souris vers un deuxième point, cliquer pour créer le deuxième point, etc :
        </p>
        <img alt='image' className={styles.contentImage} src={startDrawPoly}/>
        <p className={styles.content}>Double cliquez pour terminer le tracé :</p>
        <img alt='image' className={styles.contentImage} src={finishDrawPoly}/>
        <p className={styles.content}>Cliquer sur la croix pour retirer le commentaire :</p>
        <img alt='image' className={styles.contentImage} src={drawPolyFinish2}/>
        <p className={styles.content}>
          Vous pouvez ajouter des annotations en cliquant sur {`${'l\'outils'}`} écrire sur la carte :
        </p>
        <img alt='image' className={styles.contentImage} src={writeAnnotation}/>
        <p className={styles.content}>
          Dans notre exemple, nous allons ajouter les postes de tir :
        </p>
        <img alt='image' className={styles.contentImage} src={annotations}/>
        <p className={styles.content}>Recentrer pour préparer la capture {`${'d\'écran'}`} :</p>
        <img alt='image' className={styles.contentImage} src={ScreenShot}/>
        <p className={styles.content}>
          Réaliser la capture {`${'d\'écran'}`} avec {`${'l\'utilitaire'}`} de votre
          ordinateur (Touches <b>Windows + Shift + S</b> pour Windows, <b>Command + Maj + 4</b> sur Mac):
        </p>
        <img alt='image' className={styles.contentImage} src={screenshotResult}/>
        <p className={styles.content}>
          Vous pouvez maintenant ajouter ce plan à <Link to='/app/domains'>votre domaine</Link>
        </p>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </Header>
  )
}

export default PlanTutorialPage
