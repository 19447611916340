import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import SectionTitle from 'components/UI/SectionTitle/SectionTitle'

// Helpers
import { resizeImage } from 'helpers/imagesHelper'

// Styles
import styles from './DomainFormPicture.module.css'

// Icons
import pictureIcon from 'assets/icons/common/picture.svg'
import addPictureIcon from 'assets/icons/common/addPicture.svg'
import closeIcon from 'assets/icons/common/close.svg'

// Texts
import domainsTexts from 'assets/texts/domains/fr.json'

const DomainFormPictures = ({ formChangeHandler, domain }) => {
  const imageReference = useRef()
  const [pictureInputFiles, setPictureInputFiles] = useState([])
  const [isInitial, setIsInital] = useState(true)

  const handlePictureDelete = (index) => {
    const newPictureInputFiles = [...pictureInputFiles]
    newPictureInputFiles.splice(index, 1)
    setPictureInputFiles(newPictureInputFiles)
  }

  useEffect(() => {
    formChangeHandler({ photos: pictureInputFiles })
  }, [pictureInputFiles])

  const convertPhotosToFiles = async (photo) => {
    const response = await fetch(photo.photo, { cache: 'no-store' }) // https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
    const blob = await response.blob()
    const file = new File(
      [blob],
      photo.photo.substring(photo.photo.lastIndexOf('/') + 1).split('?')[0],
      { type: blob.type }
    )
    setPictureInputFiles(previousState => [...previousState, file])
  }

  useEffect(() => {
    if (domain && isInitial) {
      domain.photos.forEach(photo => {
        convertPhotosToFiles(photo)
      })
      setIsInital(false)
    }
  }, [domain])

  return (
    <div className={styles.container}>
      <SectionTitle
        icon={pictureIcon}
        textContent={domainsTexts.form.addPictures.title}
      />
      <div className={styles['pictures-container']}>
        {pictureInputFiles.map((picture, index) => {
          return (
            <div key={index} className={styles.picture}>
              <img src={URL.createObjectURL(picture)} />
              <div
                className={styles.close}
                onClick={() => handlePictureDelete(index)}
              >
                <img src={closeIcon} />
              </div>
            </div>
          )
        })}
        <label htmlFor='imageInput' className={styles.label}>
          <img id='imageTag' src={addPictureIcon}></img>
        </label>
        <input
          id='imageInput'
          className={styles.input}
          type='file'
          accept='.jpg,.jpeg,.png'
          ref={imageReference}
          multiple
          onChange={async () => {
            // eslint-disable-next-line no-undef
            const files = imageInput.files
            if (files) {
              const resizedFiles = []
              for (const file of files) {
                const image = await resizeImage(file)
                resizedFiles.push(image)
              }
              setPictureInputFiles((previousState) => [
                ...previousState,
                ...resizedFiles
              ])
              // eslint-disable-next-line no-undef
              imageInput.value = ''
            }
          }}
        />
      </div>
    </div>
  )
}

DomainFormPictures.propTypes = {
  formChangeHandler: PropTypes.func,
  domain: PropTypes.object
}

export default DomainFormPictures
