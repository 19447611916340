import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { capitalizeUserName } from 'helpers/nameFormat'
import { formatDate } from 'helpers/dateFormat'
import huntsTexts from 'assets/texts/hunts/fr.json'
import { getItineraryLink } from 'helpers/huntsFormat'

const CalendarButton = ({ hunt, huntDomain, huntOwner, currentUser }) => {
  const getEventName = () => {
    if (hunt.name) return hunt.name
    if (huntOwner.email === currentUser.email) return huntsTexts.calendar.eventName.owner
    return `${huntsTexts.calendar.eventName.invitee} ${capitalizeUserName(huntOwner, currentUser)}`
  }
  const getDescription = () => {
    return `${huntsTexts.calendar.description} ${getItineraryLink(huntDomain)}`
  }
  const getDatetimeInfo = useMemo(() => {
    const date = Date.parse(hunt.rendez_vous_datetime)
    const startDate = formatDate({ date, format: 'YYYY-MM-DD' })
    const startTime = formatDate({ date, format: 'HH:mm' })
    return { startDate, startTime, endTime: '23:00' }
  }, [hunt])

  return (
      <AddToCalendarButton
        name={getEventName()}
        label={huntsTexts.calendar.label}
        description={getDescription()}
        startDate={getDatetimeInfo.startDate}
        startTime={getDatetimeInfo.startTime}
        endTime={getDatetimeInfo.endTime}
        timeZone='Europe/Paris'
        status='CONFIRMED'
        organizer={`${capitalizeUserName(huntOwner, currentUser, true)}|${huntOwner.email}`}
        hideCheckmark={true}
        trigger='click'
        styleLight="--btn-background: #ff824d; --btn-background-hover: #ff9762; --btn-text: #fff; --btn-text-hover: #fff; --btn-border: none; --btn-border-hover: none; --btn-shadow: none; --btn-shadow-hover: none; --btn-text: #fff; --font: Gilroy Regular, Times, serif;"
        hideBranding={true}
        size={4}
        options={['Apple', 'Google', 'Outlook.com', 'iCal| Fichier générique']}
    />)
}
CalendarButton.propTypes = {
  hunt: PropTypes.object,
  huntDomain: PropTypes.object,
  huntOwner: PropTypes.object,
  currentUser: PropTypes.object
}
export default CalendarButton
