import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import Input from 'components/UI/Input/Input'
import SectionTitle from 'components/UI/SectionTitle/SectionTitle'

// Styles
import styles from './HuntFormAbout.module.css'

// Icons
import infoIcon from 'assets/icons/common/info.svg'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'

const HuntFormAbout = ({ handleFormChange, hunt }) => {
  const [inputValue, setInputValue] = useState()

  const inputChangeHandler = ({ currentTarget: { name, value } }) => {
    setInputValue((previousState) => {
      return { ...previousState, [name]: value }
    })
  }

  useEffect(() => {
    inputValue && handleFormChange(inputValue)
  }, [inputValue])

  return (
    <div className={styles.container}>
      <SectionTitle icon={infoIcon} textContent={huntsTexts.form.about.title} />
      <Input
        id='name'
        type='name'
        name='name'
        htmlFor='name'
        className={styles.name}
        labelText={huntsTexts.form.about.inputs.name}
        placeholder={huntsTexts.form.about.placeholder.name}
        onChange={inputChangeHandler}
        value={inputValue?.name ? inputValue.name : hunt?.name ? hunt.name : ''}
        required={true}
      />
    </div>
  )
}

HuntFormAbout.propTypes = {
  handleFormChange: PropTypes.func,
  hunt: PropTypes.object
}

export default HuntFormAbout
