import Resizer from 'react-image-file-resizer'

export const resizeImage = (file) => {
  let quality = 100
  if (file.size > 4000000) { // 4MB image file
    quality = 90
  }
  if (file.size > 8000000) { // 8MB image file
    quality = 85
  }
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      3000,
      3000,
      'JPEG',
      quality,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })
}
