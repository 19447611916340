import React from 'react'
import PropTypes from 'prop-types'

// Components
import CardsButtons from 'components/UI/CardsButtons/CardsButtons'

// Styles
import styles from './DomainsCard.module.css'

// Icons
import domainsNoPicture from 'assets/icons/domains/domainsNoPicture.svg'
import domainsLocalization from 'assets/icons/domains/domainsLocalization.svg'
import domainsOwner from 'assets/icons/domains/domainsOwner.svg'
import domainsHunts from 'assets/icons/domains/domainsHunts.svg'

// Texts
import domainsTexts from 'assets/texts/domains/fr.json'
import { capitalizeAllWords, formatUserName } from 'helpers/nameFormat'
import { useSelector } from 'react-redux'

const DomainsCard = ({ id, domain, huntCount, actions }) => {
  const currentUser = useSelector((state) => state.user.currentUser)
  const multipleHuntsMark = huntCount > 1 ? 's' : ''
  return (
        <div className={styles.container}>
            <div className={styles['photo-card-and-name']}>
                <div className={styles.photo}>
                    <img src={domain.photos.length === 0 ? domainsNoPicture : domain.photos[0].photo}/>
                </div>
                <div className={styles.title}>
                    <p>{capitalizeAllWords(domain.name)}</p>
                </div>
            </div>
            <div className={styles.info}>
                <div className={styles.hour}>
                    <img src={domainsOwner}/>
                    <p>{`${domainsTexts.home.withDomain.cards.owner} : ${formatUserName(currentUser, currentUser)}`}</p>
                </div>
                {domain.city && (<div className={styles.localization}>
                    <img src={domainsLocalization}/>
                    <p>{domain.city}</p>
                </div>)}
                {huntCount > 0 && (
                    <div className={styles.hunts}>
                        <img src={domainsHunts}/>
                        <p>{`${huntCount} chasse${multipleHuntsMark} réalisée${multipleHuntsMark}`}</p>
                    </div>)}
            </div>
            <CardsButtons actions={actions} id={domain.id}/>
        </div>
  )
}

DomainsCard.propTypes = {
  id: PropTypes.number,
  domain: PropTypes.object,
  huntCount: PropTypes.number,
  actions: PropTypes.array
}

export default DomainsCard
