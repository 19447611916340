import React from 'react'
import PropTypes from 'prop-types'

// Components
import HuntsCard from '../HuntsCard/HuntsCard'

// Helpers
import { formatDateToFrenchDate } from 'helpers/dateFormat'

// Styles
import styles from './HuntsList.module.css'

const HuntsList = ({ hunts, huntMonth, actions }) => {
  return (
    <div className={styles.list} >
      {hunts
        .filter(
          (hunt) =>
            formatDateToFrenchDate(new Date(hunt.rendez_vous_datetime), {
              month: 'long',
              year: 'numeric'
            }) === huntMonth
        )
        .map((hunt) => {
          return (
            <HuntsCard
              key={hunt.id}
              hunt={hunt}
              actions={actions}
              isHomePage={true}
            />
          )
        })}
    </div>
  )
}

HuntsList.propTypes = {
  hunts: PropTypes.array,
  actions: PropTypes.array,
  huntMonth: PropTypes.string
}

export default HuntsList
