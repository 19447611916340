import React from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './ButtonsWrapper.module.css'
import SelectorButton from '../SelectorButton/SelectorButton'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'

const ButtonsWrapper = ({ buttonsWrapperState, handleSelection, domainHasNoPlan }) => {
  return (
    <div className={styles.container}>
      <SelectorButton
        textContent={huntsTexts.details.participants}
        handleSelection={handleSelection}
        name={'participants'}
        isActive={buttonsWrapperState.participants}
      />
      {
        !domainHasNoPlan &&
        <SelectorButton
          textContent={huntsTexts.details.plans}
          handleSelection={handleSelection}
          name={'plans'}
          isActive={buttonsWrapperState.plans}
        />
      }
      <SelectorButton
        textContent={huntsTexts.details.security}
        handleSelection={handleSelection}
        name={'security'}
        isActive={buttonsWrapperState.security}
      />
    </div>
  )
}

ButtonsWrapper.propTypes = {
  buttonsWrapperState: PropTypes.object,
  handleSelection: PropTypes.func,
  domainHasNoPlan: PropTypes.bool
}

export default ButtonsWrapper
