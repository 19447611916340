import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'

// Custom hooks
import useNotifications from 'hooks/useNotifications'

// Component
import Button from 'components/UI/Button/Button'
import CardsContainer from 'components/UI/CardsContainer/CardsContainer'
import HuntsList from '../HuntsList/HuntsList'

// Helpers
import { toUniqueArray } from 'helpers/arrayFormat'
import { formatHuntsToHuntsDatesList } from 'helpers/huntsFormat'

// Styles
import styles from './HuntsOverlay.module.css'

// Icons
import huntTitleIcon from 'assets/icons/hunts/huntsTitle.svg'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'

const HuntsOverlay = ({ hunts, actions, withTitle = true, withCallToActions = true }) => {
  const navigate = useNavigate()
  const huntsDatesList = formatHuntsToHuntsDatesList(hunts)
  const huntsDatesMonths = toUniqueArray(
    huntsDatesList.map((date) => date.month)
  )
  const navigateToHuntForm = () => navigate('/app/hunt-form/create')
  const navigatePastHunts = () => navigate('/app/hunts', { state: { pastHunts: true } })
  const location = useLocation()
  const { openNotification, contextHolder } = useNotifications()
  const huntIsUpdated = location.state && location.state.type === 'hunt' && location.state.update === 'success'
  const huntIsDeleted = location.state && location.state.type === 'hunt' && location.state.delete === 'success'

  useEffect(() => {
    if (!(location.state && location.state.type === 'hunt')) return
    if (huntIsUpdated) {
      openNotification({
        message: huntsTexts.form.update.notifications.success.title,
        description: huntsTexts.form.update.notifications.success.message,
        className: 'notification-success'
      })
    }
    if (huntIsDeleted) {
      openNotification({
        message: huntsTexts.form.delete.notifications.success.title,
        description: huntsTexts.form.delete.notifications.success.message,
        className: 'notification-success'
      })
    }
    window.history.replaceState({}, document.title)
  }, [location])

  if (hunts.length > 0) {
    return (
      <div className={styles.container}>
        <>{contextHolder}</>
        <div className={styles.grid}>
          {withTitle && (
            <div className={styles.title}>
              <img src={huntTitleIcon} />
              <h3>
                {hunts.length === 1 ? huntsTexts.myHunt : huntsTexts.myHunts}
              </h3>
            </div>
          )}
          {huntsDatesMonths.map((huntMonth) => (
            <CardsContainer key={huntMonth} month={huntMonth}>
              <HuntsList
                hunts={hunts}
                huntMonth={huntMonth}
                actions={actions}
              />
            </CardsContainer>
          ))}
          {
            withCallToActions &&
            <div className={styles.buttons}>
              <Button
                type='button'
                content={huntsTexts.actions.create}
                className='call-to-action'
                onClick={navigateToHuntForm}
              />
              <Button
                type='button'
                content={huntsTexts.actions.previousHunts}
                className='inversed-call-to-action'
                onClick={navigatePastHunts}
              />
            </div>
          }
        </div>
      </div>
    )
  }
}

HuntsOverlay.propTypes = {
  hunts: PropTypes.array,
  actions: PropTypes.array,
  withTitle: PropTypes.bool,
  withCallToActions: PropTypes.bool
}

export default HuntsOverlay
