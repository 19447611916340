import React from 'react'

// Components
import ToggleDisplay from 'components/UI/ToggleDisplay/ToggleDisplay'

// Styles
import styles from './HuntsDetailsSecurity.module.css'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'

const HuntsDetailsSecurity = () => {
  return (
    <div className={styles.container}>
      <ToggleDisplay
        title={huntsTexts.details.rules.general.title}
        content={huntsTexts.details.rules.general.content}
      />
      <ToggleDisplay
        title={huntsTexts.details.rules.special.title}
        content={huntsTexts.details.rules.special.content}
      />
        <div className={styles['main-rule']}>{huntsTexts.details.rules.mainRule}</div>
    </div>
  )
}

export default HuntsDetailsSecurity
