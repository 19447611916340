import React from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './SelectorButton.module.css'

const SelectorButton = ({ textContent, handleSelection, name, isActive }) => {
  return (
    <button
      className={`${styles.button} ${isActive ? styles.active : ''}`}
      onClick={handleSelection}
      name={name}
    >
      {textContent}
    </button>
  )
}

SelectorButton.propTypes = {
  textContent: PropTypes.string,
  name: PropTypes.string,
  handleSelection: PropTypes.func,
  isActive: PropTypes.bool
}

export default SelectorButton
