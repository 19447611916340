import React from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './Initials.module.css'
import { formatUserNameToInitials } from 'helpers/nameFormat'

const Initials = ({ user }) => {
  return (
    <div className={styles.container}>
      <p>{formatUserNameToInitials(user)}</p>
    </div>
  )
}

Initials.propTypes = {
  user: PropTypes.object
}

export default Initials
