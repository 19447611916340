import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './ToggleDisplay.module.css'

// Icons
import chevron from 'assets/icons/common/chevron.svg'

const ToggleDisplay = ({ title, content }) => {
  const [isActive, setIsActive] = useState()

  return (
    <div className={styles.container}>
      <div
        className={`${styles.title} ${
          isActive ? styles.active : styles.inactive
        }`}
        onClick={() => setIsActive((previousState) => !previousState)}
      >
        <p>{title}</p>
        <img src={chevron} />
      </div>
      {isActive && (
        <div className={styles.content}>
          <pre className={styles.content}>
            {content}
          </pre>
        </div>
      )}
    </div>
  )
}

ToggleDisplay.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
}

export default ToggleDisplay
