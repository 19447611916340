import React from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './CardsContainer.module.css'

// Icons
import dateIcon from 'assets/icons/common/date.svg'

const CardsContainer = ({ children, month }) => {
  return (
    <div className={styles.container}>
      {month && (
        <div className={styles['date-container']}>
          <img src={dateIcon} />
          <div className={styles.date}>{month}</div>
        </div>
      )}
      {children}
    </div>
  )
}

CardsContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  month: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  withDate: PropTypes.bool
}

export default CardsContainer
