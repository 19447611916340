import React from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './NotUpToDate.module.css'

// Icons
import redPoint from 'assets/icons/common/redPoint.svg'
import uploadIcon from 'assets/icons/common/upload.svg'

// Texts
import userTexts from 'assets/texts/user/fr.json'
import { Link } from 'react-router-dom'

const NotUpToDate = ({ withPhotos }) => {
  return (
    <div
      className={`${styles.container} ${
        withPhotos ? styles['with-photos'] : ''
      } not-up-to-date-container`}
    >
      <div className={styles.title}>
        <img src={redPoint} />
        <div>{userTexts.notUpToDate}</div>
      </div>
      <div className={styles.link}>
        <img src={uploadIcon} />
        <Link to='/app/user/documents'>
          {userTexts.documents.uploadMyDocuments}
        </Link>
      </div>
    </div>
  )
}

NotUpToDate.propTypes = {
  withPhotos: PropTypes.bool
}

export default NotUpToDate
