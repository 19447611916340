import axios from 'axios'
import { DELETE_DOMAIN_API, DOMAIN_API, UPDATE_DOMAIN_API } from 'services/endpoints'
import axiosService from './axiosService'
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'

const formatDomainData = (data) => {
  const formData = new FormData()
  if (data.photos.length > 0) {
    data.photos.forEach((file) => {
      formData.append('photos', file)
    })
  }
  if (data.plans.length > 0) {
    data.plans.forEach((plan) => {
      formData.append('plans', plan.image)
      formData.append('planNames', plan.name)
    })
  }
  formData.append('name', data.name)
  formData.append('address', data.address)
  formData.append('city', data.city)
  formData.append('latitude', data.latitude)
  formData.append('longitude', data.longitude)
  return formData
}

const createDomain = async (data) => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  try {
    return await axios.post(DOMAIN_API, formatDomainData(data))
  } catch (error) {
    console.log(error)
    return error
  }
}

const updateDomain = async ({ id, data }) => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  try {
    return await axios.patch(
      UPDATE_DOMAIN_API + '/' + id + '/',
      formatDomainData(data)
    )
  } catch (error) {
    console.log(error)
    return error
  }
}

const deleteDomain = async (id) => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  return await axios.delete(
    DELETE_DOMAIN_API + '/' + id + '/'
  )
}

export default {
  createDomain,
  updateDomain,
  deleteDomain
}
