import React from 'react'

import styles from './NotFoundPage.module.css'

const NotFoundPage = () => {
  return (
    <>
      <main className={styles.main}>
        <h1>{"Une erreur s'est produite !"}</h1>
        <p>{"Cette page n'existe pas"}</p>
      </main>
    </>
  )
}

export default NotFoundPage
