import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Store
import { fetchCurrentUser } from 'store/user-actions'

// Services
import huntsAPI from 'services/huntsAPI'

// Components
import ButtonsWrapper from 'components/UI/ButtonsWrapper/ButtonsWrapper'
import Footer from 'components/UI/Footer/Footer'
import HuntsCard from 'components/Hunts/HuntsCard/HuntsCard'
import Header from 'components/UI/Header/Header'
import HuntsDetailsSecurity from 'components/Hunts/HuntDetails/HuntsDetailsSecurity/HuntsDetailsSecurity'
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage'

// Helpers
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'

// Styles
import styles from './InvitationPage.module.css'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'
import { useDispatch, useSelector } from 'react-redux'
import SectionTitle from 'components/UI/SectionTitle/SectionTitle'
import infoIcon from 'assets/icons/common/info.svg'
import HuntInfo from 'components/Hunts/HuntDetails/HuntInfo/HuntInfo'
import HuntsDetailsParticipants from 'components/Hunts/HuntDetails/HuntsDetailsParticipants/HuntsDetailsParticipants'
import HuntsDetailsPlans from 'components/Hunts/HuntDetails/HuntsDetailsPlans/HuntsDetailsPlans'

const InvitationPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const accessKey = params.access_key
  const token = getAuthTokenFromLocalStorage()
  const currentUser = useSelector((state) => state.user.currentUser)
  const [hunt, setHunt] = useState()
  const [error, setError] = useState()
  const buttonsInitialWrapperState = {
    security: false,
    documents: false,
    participants: false
  }
  const [buttonsWrapperState, setButtonsWrapperState] = useState({
    ...buttonsInitialWrapperState,
    participants: true
  })
  let pictures = []
  if (hunt?.domain.photos.length > 0) pictures = pictures.concat(hunt?.domain.photos)
  if (hunt?.photos.length > 0) pictures = pictures.concat(hunt.photos)
  const withPhotos = pictures.length > 0

  const domainHasNoPlan = hunt?.domain.plans.length === 0

  const fetchHunt = async () => {
    try {
      const fetchedHunt = await huntsAPI.getHuntByAccessKey(accessKey)
      return fetchedHunt
    } catch (error) {
      setError(error)
    }
  }

  const fetchPageData = async () => {
    const fetchedHunt = await fetchHunt()
    setHunt(fetchedHunt)
    dispatch(fetchCurrentUser(token))
  }

  const handleSelection = (event) => {
    const { name } = event.currentTarget
    setButtonsWrapperState({ ...buttonsInitialWrapperState, [name]: true })
  }

  const handleParticipationClick = async () => {
    if (currentUser) {
      await huntsAPI.updateHuntInvitationAnswer(hunt.id, 'YES')
      navigate(`/app/hunt/${hunt.id}`, { state: { huntJoined: 'success' } })
    } else {
      navigate('/auth', { state: { goTo: 'app/hunt', id: hunt.id } })
    }
  }

  useEffect(() => {
    fetchPageData()
  }, [])

  const huntActions = [
    {
      text: huntsTexts.details.cards.actions.participate,
      action: handleParticipationClick,
      modal: true
    }
  ]

  if (error?.response?.status === 404) {
    return <NotFoundPage />
  }

  if (hunt) {
    return (
      <>
        <Header
          date={hunt.rendez_vous_datetime}
          titleText={huntsTexts.details.title}
          paragraphText={huntsTexts.details.takingPlace}
          withReturn={false}
          withPhotos={true}
          withNavigation={false}
        >
          <>
            <div className={styles.content}>
              <div
                className={`${styles.wrapper} ${withPhotos ? styles['content-with-photos'] : ''}`}
              >
                <HuntsCard
                  hunt={hunt}
                  actions={huntActions}
                  withPhotos={withPhotos}
                  isInvitationPage={true}
                  photos={pictures}
                />
                {hunt.organization_free_text && (
                  <>
                    <SectionTitle
                      icon={infoIcon}
                      textContent={huntsTexts.details.organizationFreeText}
                    />
                    <HuntInfo info={hunt.organization_free_text} />
                  </>
                )}
                <ButtonsWrapper
                  buttonsWrapperState={buttonsWrapperState}
                  handleSelection={handleSelection}
                  domainHasNoPlan={domainHasNoPlan}
                />
                {buttonsWrapperState.participants ? <HuntsDetailsParticipants hunt={hunt} deleteInvitation={null} setModalIsOpen={null} setModal={null} /> : null}
                {buttonsWrapperState.plans ? <HuntsDetailsPlans domain={hunt?.domain} /> : null}
                {buttonsWrapperState.security ? <HuntsDetailsSecurity /> : null}
              </div>
            </div>
            <div className={styles.footer}>
              <Footer />
            </div>
          </>
        </Header>
      </>
    )
  }
}

export default InvitationPage
