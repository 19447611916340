import axios from 'axios'
import axiosService from './axiosService'
import {
  DOCUMENT_API,
  USER_API,
  USER_DETAILS_API,
  USER_UPDATE_API,
  CHANGE_PASSWORD_API, FORGOT_PASSWORD_API, RESET_PASSWORD_API
} from 'services/endpoints'
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'

const createNewUser = async (credentials) => {
  const response = await axios.post(USER_API, credentials)
  return response
}

const getUser = async () => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  const response = await axios.get(USER_DETAILS_API)
  return response.data
}

const uploadUserDocument = async (file, type) => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  if (!file) {
    return
  }
  try {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', type)
    const response = await axios.post(DOCUMENT_API, formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

const updateUser = async (data) => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  const formData = new FormData()
  data.avatar && formData.append('avatar', data.avatar)
  data.email && formData.append('email', data.email)
  data.phone && formData.append('phone', data.phone)
  data.first_name && formData.append('first_name', data.first_name)
  data.last_name && formData.append('last_name', data.last_name)
  const response = await axios.put(USER_UPDATE_API, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
  return response
}

const changeUserPassword = async (data) => {
  axiosService.setAxiosToken(getAuthTokenFromLocalStorage())
  const response = await axios.put(CHANGE_PASSWORD_API, data)
  return response
}

const forgotUserPassword = async ({ email }) => {
  return await axios.post(FORGOT_PASSWORD_API, { email })
}
const resetUserPassword = async ({ password, token }) => {
  return await axios.post(RESET_PASSWORD_API, { password, token })
}

export default {
  createNewUser,
  getUser,
  uploadUserDocument,
  updateUser,
  changeUserPassword,
  forgotUserPassword,
  resetUserPassword
}
