import React from 'react'
import PropTypes from 'prop-types'

import { translateMessage } from 'helpers/textFormat'

import styles from './Input.module.css'
import { useDispatch } from 'react-redux'
import { uiActions } from 'store/ui-slice'
import PhoneInput from 'react-phone-input-2'
import authenticationTexts from 'assets/texts/authentication/fr.json'
import { formatPhoneInput } from 'helpers/userValidations'

const Input = ({
  errors = null,
  htmlFor = '',
  id = '',
  containerClassname = '',
  labelClassname = '',
  inputClassname = '',
  labelText = '',
  name = '',
  onChange = () => {},
  placeholder = '',
  required = false,
  type = '',
  value = '',
  min
}) => {
  const dispatch = useDispatch()
  return (
    <div className={`${styles.container} ${containerClassname}`} >
      <label htmlFor={htmlFor} className={labelClassname}>
        {labelText}
      </label>
      {type === 'phone'
        ? (
          <PhoneInput
            country={'fr'}
            inputClass={inputClassname}
            specialLabel={null}
            disableCountryCode={true}
            masks={{ fr: '..-..-..-..-..' }}
            placeholder={authenticationTexts.placeholders.phone}
            inputProps={{
              id,
              name,
              required,
              onFocus: () => {
                if (errors) {
                  const newErrors = { ...errors }
                  typeof errors.detail === 'string' ? delete newErrors.detail : delete newErrors[name]
                  dispatch(uiActions.setErrors(newErrors))
                }
              }
            }}
            value={formatPhoneInput(value)}
            onChange={phone => onChange({ currentTarget: { name, value: phone } })}
          />
          )
        : (
      <input
        type={type}
        id={id}
        className={inputClassname}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        value={value}
        min={min}
        onFocus={() => {
          if (errors) {
            const newErrors = { ...errors }
            typeof errors.detail === 'string' ? delete newErrors.detail : delete newErrors[name]
            dispatch(uiActions.setErrors(newErrors))
          }
        }}
      />
          )}
      {errors && typeof errors.detail === 'string' && !['firstname', 'lastname'].includes(type) && translateMessage(errors.detail)
        ? <li className={styles.error}>
          <p>{translateMessage(errors.detail)}</p>
        </li>
        : errors &&
          errors[name] && !['firstname', 'lastname'].includes(type) && (
          <ul className={styles.list}>
            {errors[name].map((errorMessage, index) => {
              return (
                <li key={index} className={styles.error}>
                  <p>{translateMessage(errorMessage)}</p>
                </li>
              )
            })}
          </ul>
        )}
    </div>
  )
}

Input.propTypes = {
  errors: PropTypes.object,
  htmlFor: PropTypes.string,
  id: PropTypes.string,
  containerClassname: PropTypes.string,
  inputClassname: PropTypes.string,
  labelClassname: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  min: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default Input
