import React from 'react'
import PropTypes from 'prop-types'

// Components
import DomainsCard from '../DomainsCard/DomainsCard'

// Styles
import styles from './DomainsList.module.css'

const DomainsList = ({ domains, actions }) => {
  return (
    <div className={styles.list}>
      {domains.map((domain) => {
        const huntCount = domain.hunts.filter(
          (hunt) => Date.parse(hunt.rendez_vous_datetime) < new Date()
        ).length
        return (
          <DomainsCard
            key={domain.id}
            domain={domain}
            actions={actions}
            huntCount={huntCount}
          />
        )
      })}
    </div>
  )
}

DomainsList.propTypes = {
  domains: PropTypes.array,
  actions: PropTypes.array
}

export default DomainsList
