import authenticationTexts from 'assets/texts/authentication/fr.json'

export const translateMessage = (message) => {
  const messages = authenticationTexts.errors
  let translatedMessage = ''
  switch (message) {
    case 'This field is required.':
      translatedMessage = messages.required
      break
    case 'Enter a valid email address.':
      translatedMessage = messages.email
      break
    case 'user with this email address already exists.':
      translatedMessage = messages.emailAlreadyUsed
      break
    case 'This password is too short. It must contain at least 8 characters.':
      translatedMessage = messages.passwordTooShort
      break
    case 'This password is too common.':
      translatedMessage = messages.passwordTooCommon
      break
    case 'No active account found with the given credentials':
      translatedMessage = messages.wrongCredentials
      break
    case 'Both passwords differs':
      translatedMessage = messages.confirmedPassword
      break
    case 'Old password is not correct':
      translatedMessage = messages.oldPassword
      break
    case "Password fields didn't match.":
      translatedMessage = messages.passwordsDidntMatch
      break
    case "We couldn't find an account associated with that email. Please try a different e-mail address.":
      translatedMessage = messages.wrongCredentials
      break
    case 'Not found.':
      translatedMessage = messages.tokenAlreadyUsed
      break
    case 'user with this phone already exists.':
      translatedMessage = messages.phoneAlreadyUsed
      break
    case 'The phone number entered is not valid.':
      translatedMessage = messages.phoneInvalid
      break
    default:
      break
  }
  return translatedMessage
}

export const getFileExtension = (filename) => {
  return filename.split('?')[0].split('.').pop()
}
