import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Components
import SectionTitle from 'components/UI/SectionTitle/SectionTitle'

// Styles
import styles from './HuntFormPlan.module.css'

// Icons
import huntIcon from 'assets/icons/hunts/huntIcon.svg'
import plusIcon from 'assets/icons/common/plus.svg'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'
import HuntFormSpecies from '../HuntFormSpecies/HuntFormSpecies'

const HuntFormPlan = ({ handleFormChange, errors, setErrors, hunt }) => {
  const initialSpeciesState = { id: 1, animal: '', quota: null, ring: '' }
  const [huntingPlan, setHuntingPlan] = useState()
  const [plansToDisplay, setPlansToDisplay] = useState(hunt ? hunt.hunting_plan : [])

  const handleAddSpecies = () => {
    const maximumId =
    plansToDisplay.length > 0
      ? Math.max(...plansToDisplay.map((plan) => plan.id))
      : 0
    setHuntingPlan((previousState) => {
      if (previousState) {
        return [...previousState, { ...initialSpeciesState, id: maximumId + 1 }]
      } else {
        return [{ ...initialSpeciesState, id: maximumId + 1 }]
      }
    })
    setPlansToDisplay((previousState) => {
      return [...previousState, { ...initialSpeciesState, id: maximumId + 1 }]
    })
  }

  const handleRemoveSpecies = (id) => {
    const newPlan = plansToDisplay.filter((plan) => plan.id !== id)
    setHuntingPlan(newPlan)
    setPlansToDisplay(newPlan)
  }

  const handlePlanChange = (data) => {
    const newPlan = plansToDisplay.map((plan) => {
      if (plan.id === data.id) {
        return { ...data }
      } else {
        return plan
      }
    })
    setHuntingPlan(newPlan)
    setPlansToDisplay(newPlan)
  }

  useEffect(() => {
    huntingPlan && handleFormChange({ hunting_plan: huntingPlan })
  }, [huntingPlan])

  return (
    <>
      <SectionTitle
        icon={huntIcon}
        textContent={huntsTexts.form.plan.title}
      />
      <p className={styles.warning}>{huntsTexts.form.plan.warning}</p>
      {
        plansToDisplay
          ? plansToDisplay.map((plan, index) => {
            return (
              <HuntFormSpecies
                key={plan.id}
                plan={plan}
                count={index + 1}
                handlePlanChange={handlePlanChange}
                errors={errors}
                setErrors={setErrors}
                handleRemoveSpecies={handleRemoveSpecies}
              />
            )
          })
          : <HuntFormSpecies
              key={initialSpeciesState.id}
              plan={initialSpeciesState}
              count={1}
              handlePlanChange={handlePlanChange}
              errors={errors}
              setErrors={setErrors}
              handleRemoveSpecies={handleRemoveSpecies}
            />
      }
      <div className={styles.add} onClick={handleAddSpecies}>
        <img src={plusIcon} /> <span>{huntsTexts.form.plan.addSpecies}</span>
      </div>
    </>
  )
}

HuntFormPlan.propTypes = {
  handleFormChange: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  hunt: PropTypes.object
}

export default HuntFormPlan
