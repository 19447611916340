import React from 'react'
import { NavLink } from 'react-router-dom'

// Styles
import styles from './MainNavigation.module.css'

// Icons
import homeIcon from 'assets/icons/navigation/navigationHome.svg'
import huntIcon from 'assets/icons/navigation/navigationHunt.svg'
import domainIcon from 'assets/icons/navigation/navigationDomain.svg'
import userIcon from 'assets/icons/user/user.svg'

// Texts
import commonTexts from 'assets/texts/common/fr.json'

const MainNavigation = () => {
  return (
    <header className={styles.header}>
      <nav>
        <ul className={styles.list}>
          <li>
            <NavLink
              to='/app'
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
              end
            >
              <div className={styles['image-container']}>
                <img src={homeIcon} alt='home' />
                <p>{commonTexts.navigation.home}</p>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/app/hunts'
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
              end
            >
              <div className={styles['image-container']}>
                <img src={huntIcon} alt='hunts' />
                <p>{commonTexts.navigation.hunt}</p>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/app/domains'
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
              end
            >
              <div className={`${styles['image-container']} ${styles['domain-image']}`}>
                <img src={domainIcon} alt='domain' />
                <p>{commonTexts.navigation.domain}</p>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/app/user'
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
              end
            >
              <div className={styles['image-container']}>
                <img src={userIcon} alt='settings' />
                <p>{commonTexts.navigation.user}</p>
              </div>
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default MainNavigation
