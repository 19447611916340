import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import SectionTitle from 'components/UI/SectionTitle/SectionTitle'

// Styles
import styles from './HuntFormInfo.module.css'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'

const HuntFormInfo = ({ handleFormChange, hunt, field, icon }) => {
  const [inputValue, setInputValue] = useState()

  const inputChangeHandler = ({ currentTarget: { name, value } }) => {
    setInputValue((previousState) => {
      return { ...previousState, [name]: value }
    })
  }

  useEffect(() => {
    inputValue && handleFormChange(inputValue)
  }, [inputValue])

  return (
    <div className={styles.container}>
      <SectionTitle
        icon={icon}
        textContent={huntsTexts.form[field].title}
      />
      <textarea
        name={field}
        id={field}
        placeholder={huntsTexts.form[field].placeholder}
        onChange={inputChangeHandler}
        defaultValue={inputValue?.[field] ? inputValue[field] : hunt ? hunt[field] : ''}
      ></textarea>
    </div>
  )
}

HuntFormInfo.propTypes = {
  handleFormChange: PropTypes.func,
  hunt: PropTypes.object,
  field: PropTypes.string,
  icon: PropTypes.string
}

export default HuntFormInfo
