import axios from 'axios'
import { JWT_REFRESH_TOKEN_API, JWT_TOKEN_API } from 'services/endpoints'

const refreshToken = async (refreshToken) => {
  try {
    const response = await axios.post(JWT_REFRESH_TOKEN_API, {
      refresh: refreshToken
    })
    return response.data
  } catch (error) {
    console.log('Error', error)
  }
}

const getToken = async (credentials) => {
  const response = await axios.post(JWT_TOKEN_API, credentials)
  return response
}

export default {
  refreshToken,
  getToken
}
