import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// Store
import { userActions } from 'store/user-slice'

// Services
import huntsAPI from 'services/huntsAPI'
import usersAPI from 'services/usersAPI'

// Components
import ButtonsWrapper from 'components/UI/ButtonsWrapper/ButtonsWrapper'
import Footer from 'components/UI/Footer/Footer'
import Header from 'components/UI/Header/Header'
import HuntsDetailsParticipants from 'components/Hunts/HuntDetails/HuntsDetailsParticipants/HuntsDetailsParticipants'
import HuntsDetailsPictures from 'components/Hunts/HuntDetails/HuntsDetailsPictures/HuntsDetailsPictures'
import HuntsDetailsPlans from 'components/Hunts/HuntDetails/HuntsDetailsPlans/HuntsDetailsPlans'
import Modal from 'components/UI/Modal/Modal'

// Helpers
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'
import { displayNotUpToDate } from 'helpers/userValidations'

// Styles
import styles from './HuntDetailsPage.module.css'
import HuntsCard from 'components/Hunts/HuntsCard/HuntsCard'
import HuntsDetailsSecurity from 'components/Hunts/HuntDetails/HuntsDetailsSecurity/HuntsDetailsSecurity'

// Icons
import itineraryIcon from 'assets/icons/common/itinerary.svg'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'
import useNotifications from 'hooks/useNotifications'
import infoIcon from 'assets/icons/common/info.svg'
import securityIcon from 'assets/icons/common/security.svg'
import SectionTitle from 'components/UI/SectionTitle/SectionTitle'
import HuntInfo from 'components/Hunts/HuntDetails/HuntInfo/HuntInfo'
import Button from 'components/UI/Button/Button'
import { getItineraryLink } from '../../helpers/huntsFormat'

const HuntDetailsPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = getAuthTokenFromLocalStorage()
  const { openNotification, contextHolder } = useNotifications()
  const params = useParams()
  const id = parseInt(params.id)
  const location = useLocation()
  const [currentUser, setCurrentUser] = useState()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modal, setModal] = useState()
  const currentUserParticipates = currentUser?.hunts_as_invited
    .map((huntAsInvited) => huntAsInvited.id)
    .includes(id)
  const huntFromInvited = currentUser?.hunts_as_invited?.find((hunt) => hunt.id === id)
  const currentUserIsHuntOwner = huntFromInvited?.invited_users?.find(invitedUser => invitedUser.role === 'OWN')?.user?.id === currentUser?.id
  const huntDomain = currentUser?.hunts_as_invited?.find((hunt) => hunt?.id === id)?.domain
  const huntFromDomain = currentUserIsHuntOwner ? currentUser?.domains?.find(domain => domain.id === huntDomain?.id)?.hunts?.find(hunt => hunt?.id === id) : null
  const hunt = huntFromDomain || huntFromInvited
  const buttonsInitialWrapperState = {
    participants: false,
    security: false,
    plans: false
  }
  const [buttonsWrapperState, setButtonsWrapperState] = useState({
    ...buttonsInitialWrapperState,
    participants: true
  })

  let pictures = []
  if (huntDomain?.photos.length > 0) pictures = pictures.concat(huntDomain.photos)
  if (hunt?.photos.length > 0) pictures = pictures.concat(hunt.photos)
  const withPhotos = pictures.length > 0
  const [photos, setPhotos] = useState()
  const [isLoading, setIsLoading] = useState()
  const [hasChanged, setHasChanged] = useState(false)
  const [photosHasBeenPosted, setPhotosHasBeenPosted] = useState(false)
  const domainHasNoPlan = huntDomain?.plans.length === 0
  const huntIsCreated = location.state && location.state.type === 'hunt' && location.state.create === 'success'

  const handleSelection = (event) => {
    const { name } = event.currentTarget
    setButtonsWrapperState({ ...buttonsInitialWrapperState, [name]: true })
  }

  const fetchCurrentUser = async () => {
    try {
      const fetchedUser = await usersAPI.getUser(token)
      setCurrentUser(fetchedUser)
      dispatch(userActions.setCurrentUser(fetchedUser))
    } catch (error) {
      navigate('/app')
    }
  }

  const picturesChangeHandler = (newState) => {
    setHasChanged(true)
    setPhotos({ photos: newState })
  }

  const deleteInvitation = async (invitationId) => {
    try {
      await huntsAPI.deleteHuntInvitation(invitationId)
      setModalIsOpen(false)
      setModal(null)
      fetchCurrentUser()
      openNotification({
        message: huntsTexts.details.deleteInvite.notifications.success.title,
        className: 'notification-success'
      })
    } catch (error) {
      openNotification({
        message: huntsTexts.details.deleteInvite.notifications.error.title,
        className: 'notification-error'
      })
    }
  }

  const updateHuntInvitationAnswer = async (huntId) => {
    try {
      await huntsAPI.updateHuntInvitationAnswer(huntId, 'NO')
      navigate('/app', { state: { type: 'invite', cancelInvite: 'success' } })
    } catch (error) {
      console.log(error)
    }
  }

  const navigateToHuntFormPage = () => navigate(`/app/hunt-form/${hunt.id}`)

  const addPhotos = async () => {
    setIsLoading(true)
    const response = await huntsAPI.addHuntPhotos(hunt.id, photos)
    if (response.id === hunt.id) {
      setPhotos([])
      setHasChanged(false)
      setPhotosHasBeenPosted(true)
      fetchCurrentUser()
      openNotification({
        message: huntsTexts.details.addPhotos.notifications.success.title,
        className: 'notification-success'
      })
    } else {
      openNotification({
        message: huntsTexts.details.addPhotos.notifications.error.title,
        className: 'notification-error'
      })
    }
    setIsLoading(false)
  }

  const openCancelInvitationModal = (huntId) => {
    setModalIsOpen(true)
    setModal(<Modal
      title={huntsTexts.actions.invite.modal.title}
      message={huntsTexts.actions.invite.modal.message}
      confirmText={huntsTexts.actions.invite.modal.confirmText}
      cancelText={huntsTexts.actions.invite.modal.cancelText}
      onConfirm={() => updateHuntInvitationAnswer(huntId)}
      onClose={() => setModalIsOpen(false)}
    />)
  }

  const huntActions = []
  if (huntDomain?.latitude && huntDomain?.longitude) {
    huntActions.push({
      text: huntsTexts.details.cards.actions.itinerary,
      action: () => { window.open(getItineraryLink(huntDomain)) },
      icon: itineraryIcon
    })
  }
  if (currentUser?.id === huntDomain?.user?.id) {
    huntActions.push({
      text: huntsTexts.details.cards.actions.update,
      action: navigateToHuntFormPage
    })
  } else {
    huntActions.push({
      text: huntsTexts.details.cards.actions.cancel,
      action: openCancelInvitationModal
    })
  }

  useEffect(() => {
    if (!currentUser) {
      fetchCurrentUser()
      return
    }
    if (currentUser && !currentUserParticipates) {
      navigate('/app')
    }
    if (huntIsCreated) {
      openNotification({
        message: huntsTexts.form.create.notifications.success.title,
        description: huntsTexts.form.create.notifications.success.message,
        className: 'notification-success'
      })
    }
    if (location.state?.huntJoined === 'success') {
      openNotification({
        message: huntsTexts.huntJoined.notifications.message,
        description: huntsTexts.huntJoined.notifications.description,
        className: 'notification-success'
      })
    }
    window.history.replaceState({}, document.title)
  }, [currentUser])

  if (hunt) {
    return (
      <>
        <>{contextHolder}</>
        <>{modalIsOpen && modal}</>
        <Header
          date={hunt.rendez_vous_datetime}
          titleText={huntsTexts.details.title}
          paragraphText={huntsTexts.details.takingPlace}
          withReturn={true}
          displayNotUpToDate={displayNotUpToDate(currentUser)}
          withPhotos={true}
        >
          <>
            <div className={styles.content}>
              <div className={`${styles['sub-content']} ${withPhotos ? styles['content-with-photos'] : ''}`} >
                <HuntsCard
                  hunt={hunt}
                  huntDomain={huntDomain}
                  actions={huntActions}
                  withPhotos={withPhotos}
                  photos={pictures}
                />
                <div className={styles['pictures-container']} >
                  <HuntsDetailsPictures
                    picturesChangeHandler={picturesChangeHandler}
                    photosHasBeenPosted={photosHasBeenPosted}
                    setPhotosHasBeenPosted={setPhotosHasBeenPosted}
                  />
                  {
                    hasChanged &&
                    <Button
                      type='button'
                      content={huntsTexts.details.addPhotos.callToAction}
                      className='call-to-action'
                      onClick={addPhotos}
                      loading={isLoading}
                    />
                  }
                </div>
                {hunt.security_free_text && (
                    <>
                      <SectionTitle
                          icon={securityIcon}
                          textContent={huntsTexts.details.securityFreeText}
                      />
                      <HuntInfo info={hunt.security_free_text} style={'security'}/>
                    </>
                )}
                {hunt.organization_free_text && (
                    <>
                      <SectionTitle
                          icon={infoIcon}
                          textContent={huntsTexts.details.organizationFreeText}
                      />
                      <HuntInfo info={hunt.organization_free_text} />
                    </>
                )}
                <ButtonsWrapper
                  buttonsWrapperState={buttonsWrapperState}
                  handleSelection={handleSelection}
                  domainHasNoPlan={domainHasNoPlan}
                />
                {buttonsWrapperState.participants ? <HuntsDetailsParticipants hunt={hunt} deleteInvitation={deleteInvitation} setModalIsOpen={setModalIsOpen} setModal={setModal} /> : null}
                {buttonsWrapperState.plans ? <HuntsDetailsPlans domain={huntDomain} /> : null}
                {buttonsWrapperState.security ? <HuntsDetailsSecurity /> : null}
              </div>
            </div>
            <div className={styles.footer} ><Footer /></div>
          </>
        </Header>
      </>
    )
  }
}

export default HuntDetailsPage
