import React from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './HuntsDetailsPlans.module.css'

const HuntsDetailsPlans = ({ domain }) => {
  return <div className={styles.container}>
    {
      <div className={styles['images-container']} >
        {
          domain.plans.map((plan, index) => {
            return <div key={index} className={styles['name-and-image-container']} >
                <div className={styles['plan-name']} >{plan.name ? `${plan.name} :` : ''}</div>
                <img src={plan.image} />
            </div>
          })
        }
      </div>
    }
  </div>
}

HuntsDetailsPlans.propTypes = {
  domain: PropTypes.object
}

export default HuntsDetailsPlans
