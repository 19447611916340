import React from 'react'
import styles from './Loader.module.css'

const Loader = () => (
    <div className={styles.loader}>
        <span className={styles.bar}></span>
        <span className={styles.bar}></span>
        <span className={styles.bar}></span>
    </div>
)
export default Loader
