import React from 'react'
import styles from './LandingPage.module.css'
import Footer from 'components/UI/Footer/Footer'
import Header from 'components/UI/Header/Header'
import Button from 'components/UI/Button/Button'
import { useNavigate } from 'react-router-dom'
import landingPageImage from 'assets/images/landingPage/landing_page.jpg'
import landingPageImageMini from 'assets/images/landingPage/landing_page_mini.jpg'
import landingPageTexts from 'assets/texts/landingPage/fr.json'

const LandingPage = () => {
  const navigate = useNavigate()
  const navigateToApp = () => navigate('/auth?mode=signup')
  const navigateToHuntCreate = () => navigate('/app/hunt-form/create')
  return (
        <Header
            titleText='Bienvenue sur majourneedechasse'
            date={new Date()}
            withAvatar={false}
            withWhiteContainer={false}
            displayNotUpToDate={false}
            withNavigation={false}
            withWelcome={false}
            signInButtonContent={landingPageTexts.header.button}
        >
            <div className={styles.content}>
                <section>
                    <div className={styles['call-to-action']}>
                        <h1 className={styles.heading}>{landingPageTexts.section1.heading}</h1>
                        <p className={styles.paragraph}>{landingPageTexts.section1.p}</p>
                        <Button
                            content={landingPageTexts.section1.button}
                            className='call-to-action'
                            onClick={navigateToApp}
                        />
                    </div>
                </section>
                <section>
                    <div className={styles['text-container']}>
                        <h1 className={styles.heading2}>{landingPageTexts.section2.heading}</h1>
                    </div>
                </section>
                <section>
                    <div className={styles['app-container']}>
                        <h1 className={styles.heading3}>{landingPageTexts.section3.heading}</h1>
                        <p className={styles.paragraph}>{landingPageTexts.section3.p}</p>
                        <img className={styles['image']} alt='image' src={landingPageImage}/>
                        <img className={styles['image-mini']} alt='image' src={landingPageImageMini}/>
                        <div className={styles['last-button']}>
                            <Button
                                content={landingPageTexts.section3.button}
                                className='call-to-action'
                                onClick={navigateToHuntCreate}
                            />
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </Header>
  )
}

export default LandingPage
