import React from 'react'
import PropTypes from 'prop-types'

import styles from './Button.module.css'
import Loader from 'components/UI/Loader/Loader'

const Button = ({
  type = 'button',
  onClick = () => {},
  className = '',
  content = '',
  disabled = false,
  loading = false
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={styles[`${className}`]}
      disabled={disabled}
    >
      {content}
      {loading && (<Loader/>)}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

export default Button
