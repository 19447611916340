export const huntingPlanColors = [
  '#ff3141',
  '#1677ff',
  '#66991a',
  '#ff6633',
  '#e6b333',
  '#b34d4d',
  '#66664d',
  '#b33300',
  '#991aff'
]
