import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Store
import { fetchCurrentUser } from 'store/user-actions'

// Components
import Header from 'components/UI/Header/Header'
import HuntForm from 'components/Hunts/HuntForm/HuntForm'

// Helpers
import { getAuthTokenFromLocalStorage } from 'helpers/authentication'
import { isCurrentUserHunt } from 'helpers/userValidations'

// Texts
import huntTexts from 'assets/texts/hunts/fr.json'

const HuntFormPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentUser = useSelector((state) => state.user.currentUser)
  const location = useLocation()
  const params = useParams()
  const { id } = params
  let domain
  let hunt
  if (id === 'create') {
    domain = currentUser?.domains?.find(
      (domain) => domain.id === parseInt(location?.state?.domainId)
    )
  } else {
    hunt = currentUser?.hunts_as_invited?.find(
      (hunt) => hunt.id === parseInt(id)
    )
  }

  useEffect(() => {
    dispatch(fetchCurrentUser(getAuthTokenFromLocalStorage()))
  }, [])

  useEffect(() => {
    if (currentUser && hunt && !isCurrentUserHunt({ currentUser, hunt })) {
      navigate('/app')
    }
    if (currentUser && !hunt && params.id !== 'create') {
      navigate('/app')
    }
  }, [currentUser, params])

  return (
    <div>
      <Header
        titleText={
          hunt ? huntTexts.form.update.title : huntTexts.form.create.title
        }
        withReturn={true}
      >
        <HuntForm currentUser={currentUser} domain={domain} hunt={hunt} />
      </Header>
    </div>
  )
}

export default HuntFormPage
