import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import Input from 'components/UI/Input/Input'

// Helpers
import { translateMessage } from 'helpers/textFormat'

// Styles
import styles from './UserFormInfo.module.css'

// Texts
import userTexts from 'assets/texts/user/fr.json'

const UserFormInfo = ({ currentUser, handleChange, errors }) => {
  const [inputValue, setInputValue] = useState()

  const inputChangeHandler = ({ currentTarget: { name, value } }) => {
    setInputValue((previousState) => {
      return { ...previousState, [name]: value }
    })
  }

  useEffect(() => {
    inputValue && handleChange({ key: 'info', value: inputValue })
  }, [inputValue])

  if (currentUser) {
    return (
      <div className={styles.container}>
        <div className={styles['lastname-and-firstname-container']}>
          <Input
            id='last_name'
            type='text'
            name='last_name'
            htmlFor='last_name'
            className={styles.lastname}
            labelText={userTexts.profile.form.inputs.lastname.label}
            placeholder={userTexts.profile.form.inputs.lastname.placeholder}
            onChange={inputChangeHandler}
            value={
              inputValue?.last_name
                ? inputValue.last_name
                : currentUser.last_name
            }
            required={true}
          />
          <Input
            id='first_name'
            type='text'
            name='first_name'
            htmlFor='first_name'
            className={styles.firstname}
            labelText={userTexts.profile.form.inputs.firstname.label}
            placeholder={userTexts.profile.form.inputs.firstname.placeholder}
            onChange={inputChangeHandler}
            value={
              inputValue?.first_name
                ? inputValue.first_name
                : currentUser.first_name
            }
            required={true}
          />
        </div>
        <Input
          id='email'
          type='email'
          name='email'
          htmlFor='email'
          className={styles.email}
          labelText={userTexts.profile.form.inputs.email.label}
          placeholder={userTexts.profile.form.inputs.email.placeholder}
          onChange={inputChangeHandler}
          value={inputValue?.email ? inputValue.email : currentUser.email}
          required={true}
        />
        {errors?.email && (
          <div className={styles.error}>
            {translateMessage(errors.email[0])}
          </div>
        )}
        <Input
            id='phone'
            type='phone'
            name='phone'
            htmlFor='phone'
            className={styles.phone}
            labelText={userTexts.profile.form.inputs.phone.label}
            placeholder={userTexts.profile.form.inputs.phone.placeholder}
            onChange={inputChangeHandler}
            value={inputValue?.phone ? inputValue.phone : currentUser.phone}
            required={true}
        />
        {errors?.phone && (
            <div className={styles.error}>
              {translateMessage(errors.phone[0])}
            </div>
        )}
      </div>
    )
  }
}

UserFormInfo.propTypes = {
  currentUser: PropTypes.object,
  handleChange: PropTypes.func,
  errors: PropTypes.object
}

export default UserFormInfo
