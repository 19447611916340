import { notification } from 'antd'

const useNotifications = () => {
  const [api, contextHolder] = notification.useNotification()

  const openNotification = ({ message, description, className }) => {
    api.open({
      message,
      description,
      className
    })
  }

  return {
    openNotification,
    contextHolder
  }
}

export default useNotifications
