import React from 'react'
import PropTypes from 'prop-types'
import styles from './WhatsappButton.module.css'
import whatsappLogo from 'assets/icons/hunts/whatsappLogo.svg'
const WhatsappButton = ({ content = '', invitationLinkUrl = '' }) => {
  const onClick = () => window.open(invitationLinkUrl)
  return (
      <button
          className={styles['whatsapp-button']}
          onClick={onClick}
      >
          <img src={whatsappLogo} className={styles.logo} />
          {content}
      </button>
  )
}
WhatsappButton.propTypes = {
  content: PropTypes.string,
  invitationLinkUrl: PropTypes.string
}
export default WhatsappButton
