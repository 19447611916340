const userIsUpToDate = (user) => {
  const documentsTypes = user.documents.map((document) => document.type)
  return documentsTypes.includes('LIC') && documentsTypes.includes('VAL') && documentsTypes.includes('INS')
}

export const displayNotUpToDate = (user) => {
  return user.hunts_as_invited.length > 0 && !userIsUpToDate(user)
}

export const isCurrentUserHunt = ({ currentUser, hunt }) => currentUser?.id === hunt?.domain.user.id

export const formatPhoneInput = (phone) => {
  if (phone && phone.includes('+33')) return `0${phone.replace('+33', '')}`
  return phone
}

export const formatPhoneRequest = (phone) => `+33${phone.slice(1)}`
