import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  errors: null
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    resetInitialState (state) {
      state.errors = null
    },
    setErrors (state, action) {
      state.errors = action.payload
    },
    resetErrors (state) {
      state.errors = null
    }
  }
})

export const uiActions = uiSlice.actions

export default uiSlice
