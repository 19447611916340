import { uiActions } from './ui-slice'
import { userActions } from './user-slice'

export const resetStore = () => {
  return async (dispatch) => {
    try {
      dispatch(userActions.resetInitialState())
      dispatch(uiActions.resetInitialState())
    } catch (error) {
      console.log(error)
    }
  }
}
