import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import SectionTitle from 'components/UI/SectionTitle/SectionTitle'

// Helpers
import { resizeImage } from 'helpers/imagesHelper'

// Styles
import styles from './HuntsDetailsPictures.module.css'

// Icons
import pictureIcon from 'assets/icons/common/picture.svg'
import addPictureIcon from 'assets/icons/common/addPicture.svg'
import closeIcon from 'assets/icons/common/close.svg'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'

const HuntsDetailsPictures = ({ picturesChangeHandler, photosHasBeenPosted, setPhotosHasBeenPosted }) => {
  const imageReference = useRef()
  const [pictureInputFiles, setPictureInputFiles] = useState([])

  const handlePictureDelete = (index) => {
    const newPictureInputFiles = [...pictureInputFiles]
    newPictureInputFiles.splice(index, 1)
    setPictureInputFiles(newPictureInputFiles)
  }

  useEffect(() => {
    pictureInputFiles.length > 0 && picturesChangeHandler(pictureInputFiles)
  }, [pictureInputFiles])

  useEffect(() => {
    if (photosHasBeenPosted) {
      setPictureInputFiles([])
      setPhotosHasBeenPosted(false)
    }
  }, [photosHasBeenPosted])

  return (
    <div className={styles.container}>
      <SectionTitle
        icon={pictureIcon}
        textContent={huntsTexts.form.addPictures.title}
      />
      <div className={styles['pictures-container']}>
        {pictureInputFiles.map((picture, index) => {
          return (
            <div key={index} className={styles.picture}>
              <img src={URL.createObjectURL(picture)} />
              <div
                className={styles.close}
                onClick={() => handlePictureDelete(index)}
              >
                <img src={closeIcon} />
              </div>
            </div>
          )
        })}
        <label htmlFor='imageInput' className={styles.label}>
          <img id='imageTag' src={addPictureIcon}></img>
        </label>
        <input
          id='imageInput'
          className={styles.input}
          type='file'
          accept='.jpg,.jpeg,.png'
          ref={imageReference}
          multiple
          onChange={async () => {
            // eslint-disable-next-line no-undef
            const files = imageInput.files
            if (files) {
              const resizedFiles = []
              for (const file of files) {
                const image = await resizeImage(file)
                resizedFiles.push(image)
              }
              setPictureInputFiles((previousState) => [
                ...previousState,
                ...resizedFiles
              ])
              // eslint-disable-next-line no-undef
              imageInput.value = ''
            }
          }}
        />
      </div>
    </div>
  )
}

HuntsDetailsPictures.propTypes = {
  picturesChangeHandler: PropTypes.func,
  photosHasBeenPosted: PropTypes.bool,
  setPhotosHasBeenPosted: PropTypes.func
}

export default HuntsDetailsPictures
