import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from 'react-google-autocomplete'

import styles from './AdressAutocompleteInput.module.css'

import localization from 'assets/icons/common/localization.svg'

const AddressAutocompleteInput = ({
  htmlFor,
  labelClassname,
  labelText,
  onSelected,
  address
}) => {
  return (
    <div className={styles.container}>
      <label htmlFor={htmlFor} className={styles[labelClassname]}>
        {labelText}
      </label>
      <Autocomplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        defaultValue={address}
        onPlaceSelected={(place) => {
          onSelected(
            place.formatted_address,
            place.address_components.find((component) =>
              component?.types?.includes('locality')
            )?.short_name,
            place.geometry.location.lat(),
            place.geometry.location.lng()
          )
        }}
        options={{ types: [], componentRestrictions: { country: 'fr' } }}
      />
      <img className={styles.localization} src={localization} />
    </div>
  )
}

AddressAutocompleteInput.propTypes = {
  htmlFor: PropTypes.string,
  labelClassname: PropTypes.string,
  labelText: PropTypes.string,
  onSelected: PropTypes.func,
  address: PropTypes.string
}

export default AddressAutocompleteInput
