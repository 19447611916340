import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Helpers
import { resizeImage } from 'helpers/imagesHelper'

// Styles
import styles from './UserFormAvatar.module.css'

// Icons
import avatarDefaultIcon from 'assets/icons/user/bigAvatarIcon.svg'
import penIcon from 'assets/icons/common/pen.svg'

const UserFormAvatar = ({ currentUser, handleChange }) => {
  const [avatarFile, setAvatarFile] = useState()
  const currentUserAvatar = currentUser?.avatar

  useEffect(() => {
    avatarFile && handleChange({ key: 'info', value: avatarFile })
  }, [avatarFile])

  if (currentUser) {
    return (
      <div className={styles.container}>
        <div className={styles['label-container']}>
          <label htmlFor='avatar' className={styles['avatar-container']}>
            <div className={styles.avatar}>
              {
                avatarFile
                  ? <div className={styles['avatar-file']} ><img src={URL.createObjectURL(avatarFile.avatar)} /></div>
                  : currentUserAvatar
                    ? <div className={styles['current-user-avatar']} ><img src={currentUserAvatar} /></div>
                    : <div className={styles['avatar-default-icon']} ><img src={avatarDefaultIcon} /></div>
              }
            </div>
            <div className={styles.pen}>
              <img src={penIcon} />
            </div>
          </label>
        </div>
        <input
          id='avatar'
          type='file'
          accept='.jpg,.jpeg,.png'
          onChange={async () => {
            // eslint-disable-next-line no-undef
            const [file] = avatar.files
            if (file) {
              const image = await resizeImage(file)
              setAvatarFile({ avatar: image })
              // eslint-disable-next-line no-undef
              avatar.value = ''
            }
          }}
        />
      </div>
    )
  }
}

UserFormAvatar.propTypes = {
  currentUser: PropTypes.object,
  handleChange: PropTypes.func
}

export default UserFormAvatar
