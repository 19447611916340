import React from 'react'
import PropTypes from 'prop-types'

// Styles
import styles from './SectionTitle.module.css'

const SectionTitle = ({ icon, textContent }) => {
  return (
    <div className={styles.container}>
      <div className={styles['icon-container']}>
        <img className={styles.icon} src={icon} />
      </div>
      <h3>{textContent}</h3>
    </div>
  )
}

SectionTitle.propTypes = {
  icon: PropTypes.string,
  textContent: PropTypes.string
}

export default SectionTitle
