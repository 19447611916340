import { formatDateToFrenchDate } from './dateFormat'

export const formatHuntsToHuntsDatesList = (hunts) => {
  const huntsDates = []
  hunts.forEach((hunt) => {
    const month = formatDateToFrenchDate(new Date(hunt.rendez_vous_datetime), {
      month: 'long',
      year: 'numeric'
    })
    huntsDates.push({
      month,
      date: hunt.rendez_vous_datetime
    })
  })
  return huntsDates.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  )
}

export const getItineraryLink = (huntDomain) => {
  return `https://www.google.com/maps/dir//${huntDomain?.latitude},${huntDomain?.longitude}`
}
