import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Components
import CardsButtons from 'components/UI/CardsButtons/CardsButtons'
import DateCard from 'components/UI/DateCard/DateCard'
import HuntingPlan from './HuntingPlan/HuntingPlan'
import Initials from 'components/User/Initials/Initials'

// Helpers
import { formatDateToFrenchDate, formatMinutes } from 'helpers/dateFormat'
import { capitalizeAllWords, formatUserName } from 'helpers/nameFormat'

// Styles
import styles from './HuntsCard.module.css'

// Icons
import localization from 'assets/icons/common/localization.svg'
import huntsTime from 'assets/icons/hunts/huntsTime.svg'
import huntsOrganizer from 'assets/icons/hunts/huntsOrganizer.svg'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'
import WhatsappButton from 'components/UI/WhatsappButton/WhatsappButton'
import InvitationLinkButton from 'components/UI/InvitationLinkButton/InvitationLinkButton'
import CalendarButton from 'components/UI/CalendarButton/CalendarButton'

const HuntsCard = ({
  hunt,
  huntDomain,
  actions,
  isHomePage = false,
  withPhotos,
  isInvitationPage = false,
  photos
}) => {
  const navigate = useNavigate()
  const currentUser = useSelector((state) => state.user.currentUser)
  const slicedPhotos = photos?.slice(0, 3)
  const hasMoreThanOnePhoto = photos?.length > 1
  const hasMoreThanTwoPhotos = photos?.length > 2
  const invitedUsers = hunt?.invited_users?.filter(invitedUser => invitedUser.answer === 'YES')
  const huntOwner = hunt?.invited_users?.find(invitedUser => invitedUser.role === 'OWN')?.user
  const city = hunt.domain.city || currentUser.domains.find(domain => domain.id === hunt.domain).city

  const getImages = useMemo(() => {
    let images = []
    if (!hunt) return images
    if (hunt.domain.photos?.length > 0) {
      images = images.concat(hunt.domain.photos)
    }
    if (hunt.photos?.length > 0) {
      images = images.concat(hunt.photos)
    }
    return images.splice(0, 2)
  }, [hunt])

  const hasHuntingPlan = (hunt) => {
    return hunt.hunting_plan && hunt.hunting_plan.length > 0
  }

  const navigateToGallery = () => navigate(`/app/gallery/${hunt.id}`)

  return (
    <div
      className={`${styles.wrapper} ${withPhotos ? styles['with-photos'] : ''}`}
    >
      {withPhotos && (
        <div
          className={`${styles['photos-grid']} ${
            hasMoreThanOnePhoto ? '' : styles['one-column']
          } ${photos?.length === 2 ? styles['two-photos'] : ''} ${hasMoreThanTwoPhotos ? styles['two-rows'] : ''}`}
          onClick={isInvitationPage ? null : navigateToGallery}
        >
          {slicedPhotos.map((photo, index) => {
            return (
              <div key={index} className={styles[`box-${index + 1}`]}>
                <img src={photo.photo} />
                {index === 2 && photos.length > 3 && (
                  <div className={styles['photos-count']}>
                    <div>{`+${photos.length - 3}`}</div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
      <div
        className={`${styles.container} ${
          withPhotos ? styles['with-photos'] : ''
        }`}
      >
        <div className={styles['date-photo-name']}>
          <div className={styles['date-photos']}>
            <DateCard
              date={formatDateToFrenchDate(new Date(hunt.rendez_vous_datetime), {
                day: 'numeric',
                month: 'short'
              })}
            />
            {!isHomePage && !isInvitationPage && (<CalendarButton
                hunt={hunt}
                huntOwner={huntOwner}
                currentUser={currentUser}
                huntDomain={huntDomain}
            />)}
            {isHomePage && getImages.length > 0 && getImages.map(
              (img, index) => (<div key={index} className={styles['images-container']} ><img className={styles.photo} src={img.photo}/></div>)
            )}
          </div>
          {hunt.name && (
            <div className={styles.title}>
              <p>{capitalizeAllWords(hunt.name)}</p>
            </div>
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.localization}>
            <img src={localization} />
            <p>{city}</p>
          </div>
          <div className={styles.hour}>
            <img src={huntsTime} />
            <p>{`${new Date(
              hunt.rendez_vous_datetime
            ).getHours()}:${formatMinutes(hunt.rendez_vous_datetime)}`}</p>
          </div>
          <div className={styles.hunts}>
            <img src={huntsOrganizer} />
            <p>{`${huntsTexts.cards.organizer} : ${formatUserName(
              huntOwner,
              currentUser
            )}`}</p>
          </div>
          {hasHuntingPlan(hunt) && (
            <HuntingPlan
              hunt={hunt}
              withInfo={!isHomePage}
            />
          )}
          {invitedUsers.length > 0 && (
            <div className={styles['initials-container']}>
              {hunt?.invited_users.slice(0, 3).map((invitedUser) => {
                return (
                  <div key={invitedUser.user.email}>
                    <Initials user={invitedUser.user} />
                  </div>
                )
              })}
              {invitedUsers.length > 3 && (
                <div className={styles.initials}>{`+${
                  invitedUsers.length - 3
                }`}</div>
              )}
            </div>
          )}
          {!isHomePage && !isInvitationPage && (
            <InvitationLinkButton
              content={huntsTexts.actions.invite.link}
              invitationLinkUrl={`${process.env.REACT_APP_API_URL}/api${hunt.invitation_link}`}
            />
          )}
        {hunt.whatsapp_group?.group_invite_link && !isHomePage && !isInvitationPage && (
            <WhatsappButton
                content={huntsTexts.cards.whatsappButton}
                invitationLinkUrl={hunt.whatsapp_group.group_invite_link}
            />
        )}
        </div>
        <CardsButtons actions={actions} id={hunt.id} withPhotos={withPhotos} />
      </div>
    </div>
  )
}

HuntsCard.propTypes = {
  hunt: PropTypes.object,
  huntDomain: PropTypes.object,
  actions: PropTypes.array,
  isHomePage: PropTypes.bool,
  withPhotos: PropTypes.bool,
  isInvitationPage: PropTypes.bool,
  photos: PropTypes.array
}

export default HuntsCard
