import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import SectionTitle from 'components/UI/SectionTitle/SectionTitle'

// Styles
import styles from './HuntFormDomain.module.css'

// Icons
import domainIcon from 'assets/icons/domains/domainIcon.svg'

// Texts
import huntsTexts from 'assets/texts/hunts/fr.json'
import Select from 'components/UI/Select/Select'

const HuntFormDomain = ({
  domains,
  domain,
  hunt,
  handleFormChange,
  errors,
  setErrors
}) => {
  const [selectedDomain, setSelectedDomain] = useState()
  const domainsOptions = domains.map((domain) => {
    return { id: domain.id, name: domain.name }
  })
  const error = errors?.domain

  const handleSelect = (event) => {
    const { value } = event.currentTarget
    setSelectedDomain({ domain: value })
  }

  const handleClick = (event) => {
    if (event.target.type === 'select-one') {
      setErrors({})
    }
  }

  useEffect(() => {
    selectedDomain && handleFormChange(selectedDomain)
  }, [selectedDomain])

  useEffect(() => {
    !hunt && domain && setSelectedDomain({ domain: domain.id })
  }, [hunt, domain])

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div className={styles.container}>
      <SectionTitle
        icon={domainIcon}
        textContent={huntsTexts.form.domain.title}
      />
      <div className={styles['select-container']}>
        <Select
          defaultOptionText={huntsTexts.form.domain.placeholder.name}
          htmlFor='domain'
          id='domain'
          labelText={huntsTexts.form.domain.inputs.name}
          name='domain'
          onChange={handleSelect}
          options={domainsOptions}
          defaultOptionValue={
            hunt?.domain?.id ? hunt.domain.id : domain?.id ? domain.id : null
          }
        />
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  )
}

HuntFormDomain.propTypes = {
  domains: PropTypes.array,
  domain: PropTypes.object,
  hunt: PropTypes.object,
  handleFormChange: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func
}

export default HuntFormDomain
