import React from 'react'
import styles from './HuntInfo.module.css'
import PropTypes from 'prop-types'

const HuntInfo = ({ info, style }) => {
  const containerClassName = style === 'security' ? styles['security-container'] : styles.container
  const infoClassName = style === 'security' ? styles['security-info'] : styles.info
  return (
    <div className={containerClassName}>
        <pre className={infoClassName}>{info}</pre>
    </div>
  )
}
HuntInfo.propTypes = {
  info: PropTypes.string,
  style: PropTypes.string
}
export default HuntInfo
