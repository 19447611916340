import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Services
import { resizeImage } from 'helpers/imagesHelper'

// Styles
import styles from './UploadInput.module.css'

// Texts
import userTexts from 'assets/texts/user/fr.json'

const UploadInput = ({ type, handleChange, fileAlreadyExists }) => {
  const [file, setFile] = useState()

  const handleFileChange = async (event) => {
    if (event.target.files) {
      const file = event.target.files[0]
      if (file.type.includes('image')) {
        const image = await resizeImage(file)
        setFile(image)
      } else {
        setFile(file)
      }
    }
  }

  useEffect(() => {
    if (file) {
      handleChange({ type, file })
    }
  }, [file])

  return (
    <div className={styles.container}>
      <label htmlFor={type} className={styles.label}>
        {fileAlreadyExists
          ? userTexts.documents.updateDocument
          : userTexts.documents.chooseDocument}
      </label>
      <input
        id={type}
        type='file'
        accept='.jpg,.jpeg,.png,.pdf'
        onChange={handleFileChange}
      />
      <div className={styles.info}>
        {userTexts.documents.uploadMyDocumentsInfo}
      </div>

      <div className={styles['file-name']}>
        {file && (
          <>
            <div className={styles['choosen-text']}>
              {userTexts.documents.choosenDocument}
            </div>
            <div>
              `${file.name} - ${file.type}`
            </div>
          </>
        )}
      </div>
    </div>
  )
}

UploadInput.propTypes = {
  type: PropTypes.string,
  handleChange: PropTypes.func,
  fileAlreadyExists: PropTypes.bool
}

export default UploadInput
